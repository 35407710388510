.page-body {
    .image-with-content {
        .content {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            z-index: 3;

            @media (max-width: 991px) {
                position: static;
                transform: translate(0);
                padding: 0;
            }

            .two-col-text {
                padding: 50px;
                @media (max-width: 991px) {
                    padding:50px 32px;
                }
            }
        }
    }

    .our-values {
        padding-top: 250px;
        padding-bottom: 80px;

        @media (max-width: 991px) {
            padding: 15px 0;
        }

        .col-lg-7 {
            div {
                padding: 50px;

                @media (max-width: 991px) {
                    padding: 50px 0;
                }
            }
        }

        .flower {
            position: absolute;
            top: 15%;
            right: 0;
            width: 700px;

            @media (max-width: 991px) {
                width: 400px;
            }
        }
    }

    .two-column-text {

        .right-col,
        .left-col {
            padding: 75px;

            @media (max-width: 991px) {
                padding: 24px 0px;
            }
        }



    }

    .one-column-text {
        padding-top: 100px;
        padding-bottom: 130px;
        z-index: 3;
        position: relative;
    }


    .content-wrapper {
        padding-top: 90px;
        padding-bottom: 130px;

        .bee-img {
            position: absolute;
            top: 35%;
            left: -100px;
            transform: translateY(-50%);

            @media (max-width: 991px) {
                top: 25px;
                left: 0;
            }
        }

        @media (max-width: 991px) {
            padding-bottom: 50px;
        }

    }

    &.page-our-guardians{
        .content-with-bee-img {
            padding-bottom: 0 !important;

            @media (max-width: 991px) {
                padding-bottom: 100px !important;
            }

            @media (max-width: 767px) {
                padding-bottom: 30px !important;
            }

            @media (max-width: 575px) {
                padding-bottom: 0px !important;
            }

            .content-wrapper {
                padding-top: 100px;
                padding-bottom: 0px;
            }
        }
    }

}
