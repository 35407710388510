.modal {
    z-index: 99999;

    .modal-content {
        border: 1px solid #2d2d2d;
    }

    .modal-content {
        border-radius: 10px;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        max-width: 100%;
        min-height: 480px;
    }

    &.modal-lg {
        .modal-content {
            min-height: 480px;
        }
    }

    .close-modal {
        padding: 0 0.5rem !important;
        position: absolute;
        top: 8px;
        right: 5px;
        min-width: inherit;
        z-index: 1000;

        svg {
            width: 18px;
            height: 18px;

            path {
                fill: var(--primary-orange);
            }
        }

        &:hover {
            svg {
                path {
                    transition: .4s;
                    fill: var(--primary-orange);
                }
            }
        }
    }

    .uploader {
        padding: 30px 20px;
        border: 2px dashed var(--primary-orange);
        border-radius: 10px;
        text-align: center;
        margin-bottom: 20px;

        &.minimal {
            padding: 0;
            background: none;
            border: none;
            border-radius: 0;
        }

        .progress {
            margin: 10px 0 0;
            height: 5px;

            .progress-bar {
                background-color: var(--brand) !important;
                height: 5px;
                border-radius: 8px;
            }
        }

        .select-img {
            margin: 0 auto;
            z-index: 1;
            width: auto;
            min-width: auto;
            max-width: initial;
            padding: 15px;
            /* height: 50px; */
            border-radius: 10px
        }
    }
}


.modal-backdrop {
    z-index: 9999;
}

#responseModal {

    // .modal-body {
    //     .alert.alert-success {
    //         h3 {
    //             color: var(--black) !important;
    //         }
    //     }
    // }

    .modal-content {
        min-height: 120px;
    }

    .confirm-modal-exclamation {
        position: relative;
        margin-top: -30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        &.icon-error {
            background: var(--red);
        }

        &.icon-success {
            background: var(--green);
        }

        &.icon-info {
            background: var(--brand);
        }

        svg {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            width: 40px;
            height: 40px;
            position: absolute;
            left: 50%;
        }
    }

    .btn {
        min-width: 85px;
        border-radius: 5px;
        padding: 8px 10px;
    }

    .close-modal {
        position: absolute !important;
        top: 30px !important;
        right: -10px !important;
        border: 0;
        left: unset !important;

        svg {
            width: 15px !important;
            height: 15px !important;

            path {
                fill: var(--dark) !important;
            }
        }
    }
}

.confirm-modal {
    .modal-footer {
        border: none;
    }
}

.remove-address-modal,
.confirm-modal {
    .modal-content {
        min-height: auto !important;
    }
}

.remove-address-modal {
    .modal-content {
        padding: 0 24px !important;
    }

    .modal-dialog {
        max-width: auto !important;

        @media(min-width: 575px) {
            width: 500px !important;
        }

    }
}
