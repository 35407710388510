.page-body {
    background: var(--white-light);
    padding-top: 100px;
    color: var(--dark);
    overflow-x: hidden;
    position: relative;
    font-family: 'Museo Sans 300';
    transition: all .2s;
    font-size: 16px;

    &.with-hero {
        padding-top: 0;
    }

    a {
        text-decoration: none;
        outline: none !important;
        transition: all 0.2s;
        color: var(--white);

        &:active,
        &:focus,
        &:visited {
            outline: none !important;
            box-shadow: none !important;
            text-decoration: none !important;
            color: var(--white);
        }

        &:hover {
            color: var(--white);
        }

        svg {
            transition: all 0.2s;
        }
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .frame-shadow {
        box-shadow: 0 4px 40px 0 var(--dark-3);

        @media (max-width:991px) {
            // max-width: 400px;

        }
    }

    .relative {
        z-index: 5;
        position: relative;
    }

    .valign {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .full_box_link {
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .pagination-link {
        transform: translateY(-50%);

        &.swiper-button-disabled {
            opacity: 0 !important;
        }

        &:after {
            display: none;
        }
    }

    section {
        position: relative;
        display: block;
        z-index: 5;
    }

    .img-abs {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .full-box-link {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 5
    }

    button {
        outline: none !important;
        transition: all var(--transition_fast);

        &:hover,
        &:active,
        &:focus,
        &:visited {
            outline: none !important;
            box-shadow: none !important;
            text-decoration: none !important;
        }

        svg {
            transition: all var(--transition_fast);
        }
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;

        &::before {
            display: block;
            content: "";
        }

        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .embed-responsive-21by9 {
        &::before {
            padding-top: percentage(9 / 21);
        }
    }

    .embed-responsive-16by9 {
        &::before {
            padding-top: percentage(9 / 16);
        }
    }

    .embed-responsive-4by3 {
        &::before {
            padding-top: percentage(3 / 4);
        }
    }

    .embed-responsive-1by1 {
        &::before {
            padding-top: percentage(1 / 1);
        }
    }

    .container {
        padding: 0 15px;
        max-width: var(--containerWidth);
        width: 100%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }

    .container.container-large {
        max-width: var(--containerLargeWidth);
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;

        @media only screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }

    .container.container-small {
        padding: 0 15px;
        max-width: var(--containerSmallWidth);
        width: 100%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }

    .container.container-medium-small {
        padding: 0 15px;
        max-width: var(--containerMediumWidth);
        width: 100%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }

    .container.container-extra-small {
        padding: 0 15px;
        max-width: var(--containerExtraSmallWidth);
        width: 100%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }

    .form-group {
        position: relative;
        margin: 0 0 48px;

        @media only screen and (max-width: 1200px) {
            margin: 0 0 40px;
        }

        @media only screen and (max-width: 991px) {
            margin: 0 0 30px;
        }

        @media only screen and (max-width: 767px) {
            margin: 0 0 20px;
        }

        &.form-group-inline {
            border-bottom: 1px solid var(--gray-5);

            .form-group-left {
                -ms-flex: 0 0 105px;
                flex: 0 0 105px;
                max-width: 105px;
            }

            .form-group-right {
                -ms-flex: 0 0 calc(100% - 105px);
                flex: 0 0 calc(100% - 105px);
                max-width: calc(100% - 105px);
            }

            .form-control {
                background: none;
                border: none;
                border-radius: 0;
                height: 23px;

                &:focus {
                    background-color: none;
                    outline: 0;
                    box-shadow: none;
                }
            }
        }

        label {
            font-family: 'Museo Sans 500';
            margin: 0;
            color: var(--gray-dark);
            font-size: 12px;
        }

        textarea.form-control {
            height: 73px;
            padding: 0;

            @media only screen and (max-width: 1200px) {
                height: 60px;
            }

            @media only screen and (max-width: 991px) {
                height: 50px;
            }

            @media only screen and (max-width: 767px) {
                height: 40px;
            }
        }

        .form-control {
            background: none;
            border: none;
            border-bottom: 1px solid var(--gray-5);
            border-radius: 0;
            height: 40px;

            &:focus {
                background-color: none;
                outline: 0;
                box-shadow: none;
            }
        }

        .select2-container {
            .select2-selection--single {
                border: 1px solid var(--gray);
                border-radius: 4px;
                height: 40px;

                @media only screen and (max-width: 767px) {
                    height: 35px;
                }

                .select2-selection__rendered {
                    color: var(--blue);
                    padding-left: 15px;
                    padding-right: 50px;
                    line-height: 40px;

                    @media only screen and (max-width: 767px) {
                        line-height: 35px;
                    }

                    @media only screen and (max-width: 767px) {
                        line-height: 38px;
                    }
                }

                .select2-selection__arrow {
                    height: 13px;
                    top: 50%;
                    right: 24px;

                    @media only screen and (max-width: 1200px) {
                        right: 10px;
                    }

                    @media only screen and (max-width: 991px) {
                        width: 20px;
                    }

                    @media only screen and (max-width: 767px) {
                        width: 15px;
                    }

                    b {
                        display: none;
                        ;
                    }
                }
            }
        }

        &.parsley-error {
            label {
                color: var(--error);
            }

            .form-control {
                border-bottom: 1px solid var(--error);
            }

            &.form-group-inline {
                border-bottom: 1px solid var(--error);

                .form-control {
                    border-bottom: none;
                }
            }

            .select2-container {
                .select2-selection--single {
                    border: 1px solid var(--danger);
                }
            }
        }

        .parsley-errors-list {
            position: absolute;
            list-style: none;
            z-index: 10;
            right: 0;
            left: 0;
            z-index: 10;
            color: var(--error);
            padding: 2px 0;
            opacity: 0;
            font-size: 12px;
            margin: 0;
            transition: all .3s;
            text-align: right;
            background: none;
            bottom: 0;
            transform: translateY(100%);
            line-height: 1;

            &.filled {
                opacity: 1;
            }
        }
    }

    .select2 {
        font-size: 16px;
        min-width: 147px;
    }

    .select2-container {
        font-size: 16px;
        width: 100% !important;

        .select2-selection--single {
            height: 40px;
            border-radius: 0;

            .select2-selection__rendered {
                color: var(--blue);
                line-height: 40px;
                padding-left: 15px;
                padding-right: 20px;
            }

            .select2-selection__arrow {
                height: 13px;
                right: 10px;
                background: url(../img/arrow.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                border: none;
                width: 23px;
                top: 50%;
                transition: all 0.5s;
                transform: translateY(-50%);

                b {
                    display: none;
                    ;
                }
            }

            &[aria-expanded="true"] {
                .select2-selection__arrow {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }

    .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: var(--blue-light);
        color: white;
    }

    .option {
        &.parsley-error {
            [type="checkbox"] {

                &:not(:checked)+label:before,
                &:checked+label:before {
                    border-color: var(--danger) !important;
                }
            }
        }

        @media only screen and (max-width: 767px) {}

        [type="checkbox"] {

            &:not(:checked),
            &:checked {
                position: absolute;
                left: -9999px;
            }

            +label {
                position: relative;
                cursor: pointer;
                font-size: 12px;
                padding: 0px 0 1px 30px;
                margin-bottom: 0;
                color: var(--dark);
                font-family: var(--font-regular);

                @media only screen and (max-width: 991px) {
                    font-size: 12px;
                }

                @media only screen and (max-width: 767px) {}
            }

            &:not(:checked)+label:before,
            &:checked+label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0px;
                height: 20px;
                width: 20px;
                border: 1px solid var(--dark);
                border-radius: 5px;
                box-shadow: none;
                background: transparent;
                transition: all var(--transition_fast);
                background-color: transparent;

                @media only screen and (max-width: 767px) {}
            }

            &:checked+label:before {
                border: 1px solid var(--primary-orange) !important;
            }

            &:not(:checked)+label:after,
            &:checked+label:after {
                content: "";
                position: absolute;
                background: url("../img/check.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                width: 12px;
                height: 8px;
                left: 4px;
                top: 6px;
                transition: all 0.2s;
            }

            /* checked mark aspect changes */
            &:not(:checked)+label:after {
                opacity: 0;
                transform: scale(0);
            }

            &:checked+label:after {
                opacity: 1;
                transform: scale(1);
            }

            /* disabled checkbox */
            &:disabled:not(:checked)+label:before,
            &:disabled:checked+label:before {
                box-shadow: none;
                border-color: #bbb;
                background-color: #ddd;
            }

            &:disabled:checked+label:after {
                color: #999;
            }

            &:disabled+label {
                color: #aaa;
            }

            /* accessibility */
            &:checked:focus+label:before,
            &:not(:checked):focus+label:before,
            &+label:hover:before &:not(:checked):focus+label:hover:before {
                border: 1px solid var(--brand) !important;
            }

        }

        [type="radio"] {

            &:not(:checked),
            &:checked {
                position: absolute;
                left: -9999px;
            }

            +label {
                position: relative;
                cursor: pointer;
                font-size: 16px;
                padding: 2px 0 2px 30px;
                margin-bottom: 0;
                color: var(--white);
                font-family: var(--font-regular);

                @media only screen and (max-width: 991px) {
                    font-size: 14px;
                }

                @media only screen and (max-width: 767px) {}
            }

            &:not(:checked)+label:before,
            &:checked+label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                width: 16px;
                height: 16px;
                border: 1.5px solid var(--white);
                border-radius: 50%;
                box-shadow: none;
                z-index: 1;

                @media only screen and (max-width: 767px) {
                    height: 14px;
                    width: 14px;
                }
            }

            &:not(:checked)+label:after,
            &:checked+label:after {
                content: "";
                position: absolute;
                left: 3px;
                top: 9px;
                width: 10px;
                height: 10px;
                background: #fff;
                transition: all 0.2s;
                border-radius: 50%;

                @media only screen and (max-width: 767px) {
                    width: 8px;
                    height: 8px;
                }
            }

            /* checked mark aspect changes */
            &:not(:checked)+label:after {
                opacity: 0;
                transform: scale(0);
            }

            &:checked+label:after {
                opacity: 1;
                transform: scale(1);
            }

            /* disabled checkbox */
            &:disabled:not(:checked)+label:before,
            &:disabled:checked+label:before {
                box-shadow: none;
                border-color: #bbb;
                background-color: #ddd;
            }

            &:disabled:checked+label:after {
                color: #999;
            }

            &:disabled+label {
                color: #aaa;
            }

            &:checked+label {}

            /* accessibility */
            .option [type="radio"]:checked:focus+label:before,
            .option [type="radio"]:not(:checked):focus+label:before {
                border: 5px solid var(--dark);
            }
        }
    }

    .parsley-errors-list {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        color: var(--danger);
        background: 0 0;
        list-style: none;
        z-index: 1;
        margin: 0;
        padding: 0 0 0 12px;
        font-size: 12px;

        li {
            padding: 0;
        }
    }

    #responseModal {
        .close-modal {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%);
            top: -15px;
            margin: 0;

            svg {
                width: 20px;
                height: 20px;
                fill: var(--white);
            }
        }

        .modal-content {
            background: var(--white);
        }

        .modal-dialog {
            margin: 100px auto;
        }

        .alert {
            text-align: center;
            font-family: 'Museo Sans 500';
            color: var(--blue);
            background: none;
            border: none;
            margin: 0;

            h3 {
                color: var(--blue-dark);
                margin: 0;
            }

            &.alert-error {
                h3 {
                    color: var(--dark);
                }
            }

            p {
                font-family: 'Museo Sans 500';
                color: var(--blue-dark);
            }
        }
    }

    .close-btn {
        opacity: 1;
        text-shadow: 0 1px 0 #fff;
        display: table;
        float: none;
        font-size: 1.35rem;
        margin: 30px auto;
        border-radius: 3px;
        text-align: center;
        background: none;
        border: 3px solid #fff;
        border-radius: 50%;
        line-height: 48px;
        width: 48px;

        @media only screen and (max-width: 991px) {
            border: 2px solid #fff;
            line-height: 40px;
            width: 40px;
        }

        @media only screen and (max-width: 767px) {
            width: 32px;
            line-height: 32px;
        }

        svg {
            width: 17px;
            height: 42px;
            fill: #fff;
            display: table;
            margin: 0 auto;

            @media only screen and (max-width: 991px) {
                height: 36px;
            }

            @media only screen and (max-width: 767px) {
                height: 28px;
            }
        }

        &:hover {
            background: var(--blue);
            border-color: var(--blue);

            svg {
                fill: #fff;
            }
        }

        &.larger {
            font-size: 2.35rem;
            width: 80px;
            line-height: 80px;

            @media only screen and (max-width: 991px) {
                width: 70px;
                line-height: 70px;
            }

            @media only screen and (max-width: 767px) {
                width: 60px;
                line-height: 60px;
            }

            svg {
                width: 27px;
                height: 28px;
            }
        }

        &.smaller {
            font-size: 2.35rem;
            width: 50px;
            line-height: 50px;

            @media only screen and (max-width: 991px) {
                width: 45px;
                line-height: 45px;
            }

            @media only screen and (max-width: 767px) {
                width: 40px;
                line-height: 40px;
            }
        }

        &.dark {
            background: var(--blue);

            svg {
                fill: #fff;
            }

            &:hover {
                background: #fff;

                svg {
                    fill: var(--blue);
                }
            }
        }
    }

    .z-1 {
        z-index: 1;
    }

    .z-3 {
        z-index: 3;
    }

    .z-10 {
        z-index: 10;
    }

    .z-100 {
        z-index: 100;
    }

    .btn {
        font-size: 14px;
        font-family: 'Museo Sans 700';
        text-transform: uppercase;
        letter-spacing: 2.1px;

        &.btn-default {
            text-decoration: none !important;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 0;

            .btn-text {
                color: var(--primary-orange);
                position: relative;

                &.large {
                    font-size: 44px;
                    letter-spacing: 2.1px;
                    text-transform: none;
                    @media (max-width:991px) {
                        font-size: 24px;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: var(--primary-orange);
                    transition: width 0.3s ease;
                }
            }

            &:hover {
                .btn-text {
                    &::before {
                        width: 100%;
                    }
                }
            }

            svg {
                width: 40px;
                height: 40px;
            }

            @media (max-width:575px) {
                font-size: 12px;
                letter-spacing: 0;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        &.btn-rounded {
            padding: 8px 38px;
            border: 1px solid var(--primary-orange);
            background-color: var(--light-grey);
            border-radius: 60px;
            color: var(--primary-orange);

            @media (max-width:991px) {
                // font-size: 12px;
                letter-spacing: 0;
                padding: 8px 30px;
            }

            &:hover,
            &:focus {
                background-color: var(--primary-orange);
                color: white;
            }
        }

        &.arrow-btn {
            @media (max-width:575px) {
                padding: 0;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .linear-bg {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background: linear-gradient(133deg, #908071 0%, #7c5131 100%);
            mix-blend-mode: hue;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;
        }
    }

    .linear-bg-1 {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background: linear-gradient(133deg, #908071 0%, #7c5131 100%);
            mix-blend-mode: color;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;
        }
    }

    .hidden-frontend {
        display: none !important;
    }

    &.template-builder-container {
        .hidden-frontend {
            display: block !important;
        }

        .full_box_link {
            display: none !important;
        }

        .hidden-admin {
            display: none;
        }
    }

    .table-bordered,
    .table-bordered td,
    .table-bordered th {
        border: 1px solid black;
    }

    .opacity-0 {
        opacity: 0;
    }

    .opacity-50 {
        opacity: .50;
    }

    .opacity-60 {
        opacity: .60;
    }

    .opacity-75 {
        opacity: .75;
    }

    .opacity-1 {
        opacity: 1;
    }

    .bg-transparent {
        background-color: transparent !important;
    }

    .bg-white {
        background: var(--white) !important;
    }

    .bg-light {
        background: var(--white-light) !important;
    }

    .box-shadow-none {
        box-shadow: none !important;
    }

    .contact-form {
        .select2-selection__rendered {
            color: black !important;
            padding-left: 0 !important;
        }

        .select2-selection--single {
            border: none;
        }

        .select2-selection {
            border-radius: 0 !important;
            border: 1px solid var(--gray);
            box-shadow: 0 4px 40px 0 var(--dark-3);
            outline: 0;
            height: 61px !important;
            padding-bottom: 25px;
            padding-left: 17px;
            background: 0 0;
            color: var(--white);
        }

        .select2-selection__arrow {
            right: 15px !important;
            width: auto !important;
            height: auto !important;
            top: 20px !important;

            b {
                display: inline-block !important;
            }
        }

        .select2-search__field {
            outline: none !important;
        }

        .select2-results__option--highlighted,
        .select2-results__option--highlighted {
            color: var(--primary-orange) !important;
        }

        .select2-container--open {
            .select2-selection__arrow {
                b {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .border-grey-semi-transparent {
        border: 1px solid rgba(0, 0, 0, .1)
    }

    .object-fit-cover {
        object-fit: cover;
    }

    .all-wrapper {
        // overflow-x: hidden;
    }

    .alert-info {
        color: #fff;
        background-color: #F18F3F;
        border-color: #F18F3F;
    }
}
