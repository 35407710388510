.page-body {
    &.page-my-account,
    &.page-addresses,
    &.page-orders // &.page-cart, &.page-check-out 

        {
        background-color: var(--white) !important;
    }

    .product-equipment {
        border-top: 1px solid #c3c3c3;
        padding-top: 10px;

        .product-equipment-icon {
            width: 100%;
            max-width: 35px;
            padding: 0 2px;
            fill: var(--brand)-primary;
        }
    }

    .equipment,
    .specs {
        ul {
            padding: 14px 0 0;
            max-width: 470px;

            li {

                width: 50%;
                float: left;
                padding-bottom: 10px;

                @media only screen and (max-width: 575px) {
                    width: 100%;
                }

                svg {
                    width: 30px;
                    fill: var(--brand);
                    margin: -4px 6px 0 0;
                }

                .item-label {
                    min-width: 50px;
                    display: inline-block;
                }
            }

        }
    }

    .includes {
        max-width: 800px;
        padding-top: 0;

        .product-icon {
            >div {
                border-radius: 15px;
                border: 1px solid #c3c3c3;
                padding: 15px 0
            }

            text-align: center;
            padding-bottom:10px;

            .product-icon-svg {
                opacity: .7;
                max-width: 80px;
                text-align: center;
                margin: 0 auto;
            }

            .product-icon-value {
                font-size: 16px;
            }

            .product-icon-name {
                text-transform: uppercase;

            }
        }

    }

    .user-activity {
        padding: 36px 0 19px;

        @media only screen and (max-width: 991px) {
            padding: 20px 0;
            max-width: 300px;
        }

        h5 {
            font-size: 18px;
            margin-bottom: 16px;
        }

        ul {
            padding: 9px 0 0;
            margin-bottom: 23px;

            &:before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: calc(43% - 1px);
                width: 2px;
                background: var(--brand);

            }

            li {
                padding: 5px 0 40px;
                font-size: 12px;
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    top: 10px;
                    border-radius: 50%;
                    left: calc(43% - 4px);
                    border: 2px solid #fff;
                    width: 8px;
                    height: 8px;
                    background: var(--dark);

                }
            }

            .block-left {
                width: 43%;
                float: left;

            }

            .block-right {
                width: 57%;
                float: left;
                padding-left: 10px;
            }
        }

        .btn {
            margin: 0 auto;
        }
    }

    .account-notifications {
        .user-activity {
            padding: 0 0 19px;

            @media only screen and (max-width: 991px) {
                padding: 20px 0;
                max-width: 300px;
            }

            h5 {
                font-size: 18px;
                margin-bottom: 16px;
            }

            ul {
                padding: 9px 0 0;
                margin-bottom: 23px;

                &:before {
                    left: 125px;

                }

                li {
                    padding: 5px 0 40px;
                    font-size: 12px;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: "";
                        top: 10px;
                        border-radius: 50%;
                        left: 122px;
                        border: 2px solid #fff;
                        width: 8px;
                        height: 8px;
                        background: var(--dark);

                    }
                }

                .block-left {
                    width: 130px;
                    float: left;

                }

                .block-right {
                    width: calc(100% - 130px);
                    float: left;
                    padding-left: 10px;
                }
            }

            .btn {
                margin: 0 auto;
            }
        }
    }

    .account-view {
        // background: #dedede;
        padding: 63px 0;

        @media only screen and (max-width: 1200px) {
            padding: 50px 0;
        }

        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }

        @media only screen and (max-width: 767px) {
            padding: 25px 0;
        }

        .widget {
            background: #fff;
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 18px 25px;

            @media only screen and (max-width: 1200px) {
                padding: 15px 20px;
            }

            @media only screen and (max-width: 991px) {
                padding: 15px;
            }

            @media only screen and (max-width: 767px) {
                padding: 10px;
            }

            &.account-welcome {

                p,
                h3 {
                    margin-bottom: 13px;

                    @media only screen and (max-width: 991px) {
                        margin-bottom: 10px;
                    }

                    @media only screen and (max-width: 767px) {
                        margin-bottom: 5px;
                    }
                }

                .btn {
                    max-width: 220px;
                    margin: 34px auto;
                    font-size: 12px;
                    display: block;
                    padding: 10px 15px 8px;

                    @media only screen and (max-width: 991px) {
                        margin: 25px 0;
                    }

                    @media only screen and (max-width: 767px) {
                        margin: 15px 0;
                    }
                }

                .contact-info {
                    li {
                        padding-bottom: 2px;

                        b.country-label {
                            min-width: 60px;
                            display: inline-block;
                        }
                    }
                }


            }

            &.account-notifications {
                h5 {
                    margin-bottom: 15px;

                    svg {
                        position: relative;
                        top: -4px;
                    }

                    .notifications-counter {
                        background: var(--brand);
                        color: #fff;
                        border-radius: 6px;
                        padding: 1px 7px 0;
                        font-size: 10px;
                        margin-left: 2px;
                        line-height: 1;
                        position: relative;
                        top: -1px;
                    }
                }

                tr {
                    color: var(--grey);

                    .title {
                        text-align: right;

                        span {
                            max-width: 205px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                            white-space: nowrap;
                            display: block;
                            float: right;
                        }
                    }

                    .caption {

                        span,
                        b {
                            display: block;
                            float: left;
                        }

                        .pending-counter {
                            background: #dedede;
                            border-radius: 5px;
                            padding: 5px 10px 5px 25px;
                            position: relative;
                            font-size: 11px;
                            line-height: 1;
                            display: block;
                            float: left;
                            margin-left: 25px;

                            @media only screen and (max-width: 1200px) {
                                margin-left: 20px;
                            }

                            @media only screen and (max-width: 991px) {
                                margin-left: 15px;
                            }

                            @media only screen and (max-width: 767px) {
                                margin-left: 10px;
                            }

                            &:before {
                                position: absolute;
                                content: "";
                                left: 7px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 8px;
                                height: 8px;
                                background: #8eb82f;
                                border-radius: 50%;
                            }
                        }
                    }

                    .date {
                        text-align: right;
                    }

                    &.active {
                        color: var(--dark);
                    }


                }

                .view-more {
                    color: var(--grey);
                    float: right;
                    font-size: 12px;

                    &:hover {
                        color: var(--dark);
                    }
                }
            }

            &.account-upcoming-bookings {}

            &.my-trips {}

            .table {
                td:not(.table-actions) {
                    min-width: 100px;
                }

                .actions {
                    width: 70px;

                    .btn-user {
                        svg {
                            width: 30px;
                            height: 30px;
                            fill: var(--brand) !important;

                        }
                    }
                }
            }
        }

        &.my-account {
            .account-details-nav {
                border-bottom: 1px solid var(--grey);
                display: block;
                margin: 0;
                padding: 11px 0 3px;
                margin-bottom: 24px;

                li {
                    float: left;
                    padding-right: 50px;

                    @media only screen and (max-width: 1200px) {
                        padding-right: 30px;
                    }

                    @media only screen and (max-width: 991px) {
                        padding-right: 20px;
                    }

                    @media only screen and (max-width: 767px) {
                        padding-right: 10px;
                    }

                    &:last-child {
                        float: right;
                        padding: 0;
                    }

                    a {
                        font-size: 12px;

                        &[aria-expanded="false"] {
                            color: #9d8e89;

                            &.active,
                            &:hover {
                                color: var(--dark);
                            }
                        }

                        &[aria-expanded="true"] {
                            color: var(--dark);
                            pointer-events: none;
                        }
                    }
                }
            }

            form {
                padding: 0 0 50px 0;
            }
        }

        &.my-trips-details {
            .btn-back {
                color: #9d8e89;

                font-size: 12px;

                &:hover {
                    color: var(--dark);
                }
            }

            .trip-title {
                margin: 0;
                padding: 8px 0;
            }


            .trips-details-nav {
                border-bottom: 1px solid var(--grey);
                display: block;
                margin: 0;
                padding: 11px 0 3px;
                margin-bottom: 24px;

                li {
                    float: left;
                    padding-right: 50px;

                    @media only screen and (max-width: 1200px) {
                        padding-right: 30px;
                    }

                    @media only screen and (max-width: 991px) {
                        padding-right: 20px;
                    }

                    @media only screen and (max-width: 767px) {
                        padding-right: 10px;
                    }

                    a {
                        font-size: 12px;

                        &[aria-expanded="false"] {
                            color: #9d8e89;

                            &.active,
                            &:hover {
                                color: var(--dark);
                            }
                        }

                        &[aria-expanded="true"] {
                            color: var(--dark);
                            pointer-events: none;
                        }
                    }
                }
            }

            .table {
                td {
                    border-color: var(--grey);
                }

                .pic {
                    padding-left: 0;
                    min-width: 140px;

                    @media only screen and (max-width: 1200px) {
                        min-width: 100px;
                    }

                    @media only screen and (max-width: 991px) {
                        min-width: 90px;
                    }

                    @media only screen and (max-width: 767px) {
                        min-width: 75px;
                    }

                    img {
                        border-radius: 10px;
                    }
                }

                .boat-title {
                    font-size: 18px;

                    @media only screen and (max-width: 991px) {
                        font-size: 16px;
                    }
                }

                tr {
                    transition: all var(--transition_fast);
                }

                .trip-item {
                    &.open {
                        background: #fff;
                    }

                    td {
                        border-bottom: 1px solid transparent;
                    }
                }

                .trip-item-details {


                    td {
                        .trip-item-details-content {
                            background: #fff;
                            overflow: hidden;
                            height: 0;

                            .details-content {
                                padding: 20px;
                            }
                        }
                    }
                }
            }
        }

        .quote-link {
            color: var(--brand);

            &:hover {
                color: var(--dark);
            }
        }
    }

    .address-modal {
        .modal-content {
            background: #dedede;
        }
    }

    .address-item {
        border-radius: 8px;
        background: #fff;
        padding: 18px 24px;

        @media only screen and (max-width: 767px) {
            padding: 12px 16px;
        }

        .address-item-title {
            font-size: 18px;
            margin-bottom: 20px;

            @media only screen and (max-width: 991px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 15px;
            }
        }

        .address-item-info-phone,
        .address-item-address {
            font-size: 14px;

            @media only screen and (max-width: 991px) {
                font-size: 14px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }

        .address-item-info-phone {
            margin-top: 20px;
            margin-bottom: 4px;
        }

        .actions {
            font-size: 16px;
            margin-top: 20px;

            @media only screen and (max-width: 991px) {}

            li:not(:last-child) {
                position: relative;
                margin-right: 12px;
                padding-right: 20px;

                &:before {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    background-color: var(--dark);
                    content: "";
                    height: 9px;
                }
            }

            a {
                transition: all .5s;
                color: var(--dark);
                text-decoration: underline !important;
                -webkit-text-decoration-color: transparent !important;
                text-decoration-color: transparent !important;
                -webkit-transition: -webkit-text-decoration-color .25s ease;
                transition: -webkit-text-decoration-color .25s ease;
                transition: text-decoration-color .25s ease;
                transition: text-decoration-color .25s ease, -webkit-text-decoration-color .25s ease;
                will-change: text-decoration-color !important;

                &.remove-address {
                    color: #B82F2F;
                }

                &:hover {
                    -webkit-text-decoration-color: inherit !important;
                    text-decoration-color: inherit !important;
                }
            }
        }

        .edit-btn,
        .delete-btn {
            &:hover {
                svg {
                    path {
                        &:nth-child(2) {
                            transition: .4s;
                            fill: var(--primary-orange);
                        }
                    }
                }
            }
        }
    }

    .payments-details {
        overflow: hidden;

        .address-item {
            background: #f1eaea;
            border: 2px solid #f1eaea;
            cursor: pointer;
            transition: border .5s;

            &.selected {

                border: 2px solid var(--brand);

                &:before {
                    content: " ";
                    border: 1px solid #fff;
                    background: url(../img/check-white.svg) var(--brand) center;
                    background-size: 12px;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    z-index: 1;
                    top: -10px;
                    right: 5px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .upload-profile-image-modal {
        .modal-header {
            padding: 0 0 20px;
            border-bottom: none;
        }

        .modal-body {
            padding: 0;
        }

        .dropzone {
            padding: 20px 0;
            border: 2px dashed #dee2e6;
            text-align: center;
        }

        .actions {
            padding-top: 20px;

            .btn-default {
                min-width: 120px;
            }

            .btn-link {
                padding: 10px 0;
                margin-right: 20px;

                &:hover {
                    color: var(--brand);
                }
            }
        }
    }

    .review-item {
        border-radius: 15px;
        background: #fff;
        height: 100%;
        padding: 10px;

        h5 {
            padding: 15px 0 0;
        }
    }

    .table-sm {
        a:hover {
            color: #f60;
        }
    }

    .my-account-wrapper {

        .user-header {
            padding-bottom: 40px;

            .profile-pic-wrapper {
                position: relative;

                .profile-pic {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                }

                .upload-btn-wrapper {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 28px;
                    height: 28px;
                    border: 1px solid var(--primary-orange);
                    border-radius: 50%;
                    transition: all .4s;

                    &:hover {
                        background-color: var(--secondary);
                    }

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 16px;
                        height: 14px;

                        path {
                            fill: var(--primary-orange);
                        }
                    }

                    .upload-btn {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        }
    }

    .my-profile-tabs {
        padding: 20px 0;
        border: solid var(--primary-orange);
        border-width: 1px 0px;

        .tab-btn {
            font-size: 14px;
            color: var(--primary-orange);
            font-family: 'Museo Sans 700';
            margin: 0 20px;
            position: relative;
            padding: 5px 0;

            &.active,
            &:hover {
                &:after {
                    width: 100%;
                }
            }

            &:after {
                content: '';
                position: absolute;
                width: 0;
                left: 0;
                bottom: 0;
                height: 2px;
                background-color: var(--primary-orange);
                transition: .5s;
            }
        }
    }

    .addresses-container {
        .user-address-item {
            border: 1px solid rgba(108, 117, 125, 0.2);
            border-radius: 8px;
            background-color: transparent;
            padding: 15px;

            .address-btn {
                &:hover {
                    svg {
                        path {
                            &:nth-child(2) {
                                transition: all .3s;
                                fill: var(--primary-orange);
                            }
                        }
                    }
                }
            }

            .new-address-btn {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;

                &:hover {
                    .new-address-icon-text {
                        color: var(--primary-orange);

                    }
                }

                .new-address-icon-text {
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--dark);
                    font-family: 'Museo Sans 500';
                    transition: all .4s;
                }
            }

            &.new-address-wrapper {
                position: relative;
            }
        }
    }

    .reset-password-input {
        label {
            font-size: 16px !important;
            font-family: 'Museo Sans 500';
        }

        .form-control {
            padding: 0;
            border-bottom: 1px solid rgba(108, 117, 125, .2);
        }
    }

    .semi-transparent-bottom-border {
        border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
    }

    .email-input-wrapper {
        .form-control {
            box-shadow: none !important;
        }
    }

    &.page-my-account {
        .contact-form {
            .form-group {
                .form-control {
                    color: var(--black);
                }
            }
        }
    }

    &.page-addresses {
        .upper-hr {
            display: none;
        }
    }
}
