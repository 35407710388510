.page-body {

    .address-item {
        position: relative;
        padding: 18px 12px;
        background-color: rgba(255,255,255,0.6);
        color: var(--dark);
        transition: border 0.5s;
        min-height: 130px; 

        @media only screen and (max-width: 767px) {
            min-height: auto; 
        }

        svg {
            fill: var(--brand) !important;
        }

        &.selectable {
            cursor: pointer;

            &:hover {
                &:before {
                    transition: all .4s;
                    border-color: rgba(0, 0, 0, .2)
                }
            }
        }

        &.selected {

            &:before {
                border: 2px solid rgba(0,0,0,.1)
            }

            // &:after {
            //     content: " ";
            //     border: 1px solid #fff;
            //     background: url("/assets/frontend/default/img/check-white.svg");
            //     background-size: 8px;
            //     position: absolute;
            //     width: 20px;
            //     height: 20px;
            //     border-radius: 100%;
            //     z-index: 1;
            //     top: 50%;
            //     transform: translateY(-50%);
            //     right: 5px;
            //     background-repeat: no-repeat;
            // }
        }

        .address-item-title {
            font-size: 18px;
            margin-bottom: 20px;

            @media only screen and (max-width: 991px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 15px;
            }
        }

        .address-item-info-phone,
        .address-item-address {
            font-size: 14px;

            @media only screen and (max-width: 991px) {
                font-size: 14px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }

        .address-item-info-phone {
            margin-top: 20px;
            margin-bottom: 4px;
        }

        .actions {
            font-size: 16px;
            margin-top: 20px;

            @media only screen and (max-width: 991px) {}

            li:not(:last-child) {
                position: relative;
                margin-right: 12px;
                padding-right: 20px;

                &:before {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    content: "";
                    height: 9px;
                }
            }

            a {
                transition: all 0.5s;

                text-decoration: underline !important;
                -webkit-text-decoration-color: transparent !important;
                text-decoration-color: transparent !important;
                -webkit-transition: -webkit-text-decoration-color 0.25s ease;
                transition: -webkit-text-decoration-color 0.25s ease;
                transition: text-decoration-color 0.25s ease;
                transition: text-decoration-color 0.25s ease, -webkit-text-decoration-color 0.25s ease;
                will-change: text-decoration-color !important;

                &.remove-address {
                    color: #b82f2f;
                }

                &:hover {
                    -webkit-text-decoration-color: inherit !important;
                    text-decoration-color: inherit !important;
                }
            }
        }

        &.new-address {
            &:hover {
                color: var(--primary-orange);
                transition: .4s;

                svg {
                    path {
                        &:nth-child(2) {
                            fill: var(--primary-orange);
                            transition: .4s;
                        }
                    }
                }
      
            }
        }

        &.other-address {
            &:hover {
                color: var(--primary-orange);
                transition: all .4s;

                svg {
                    path {
                        transition: all .4s;
                        fill: var(--primary-orange);
                    }
                }
            }
        }
        &:hover {
        &:before {
            transition: .4s;
            border: 2px solid rgba(0,0,0,.2)
        }
    }
    }

    // .billing-address-wrapper,
    // .shipping-address-wrapper {
    //     &:first-child {
    //         .address-item {
    //             cursor: unset !important;
    //         }
    //     }
    // }
}
