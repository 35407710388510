.page-body {
    .our-legacy-section {
        padding: 120px 0;

        @media (max-width: 991px) {
            padding: 30px 0;
        }

        video {
            transform: translate(45px, 50px);
            z-index: 3;
            position: relative;
            height: 750px;
            width: 100%;
            object-fit: cover;
        }

        .legacy-card {
            background: var(--gray);
            box-shadow: 0px 4px 40px 0px var(--dark-3);
            padding: 65px 25px 50px 95px;
            min-height: 1127px;
            z-index: 2;

            @media (max-width: 991px) {
                padding: 25px;
            }
        }

        .girl-with-flower {
            z-index: 1;
            top: 0;
            right: 0;
            transform: translate(40%, -50%);
            width: 283px;
            height: 510px;

            @media (max-width: 991px) {
                width: 30%;
            }

            @media (max-width: 767px) {
                width: 100%;
                transform: translate(40%, 4%);
                z-index: 2;
                opacity: 0.2;
            }
        }

        .honeycomb {
            transform: translate(45px, 50px);
            z-index: 3;
            position: relative;

            @media (max-width: 991px) {
                transform: translate(0);
               // display: none;
            }
        }
    }

    &.page-our-legacy {
        .content-main {
            @media (max-width: 991px) {
                padding: 30px 0 !important;
            }

            &:first-of-type {
                padding-top: 150px !important;

                @media (max-width: 991px) {
                    padding: 70px 0 !important;
                }
            }

            &:nth-of-type(2) {
                @media (max-width: 991px) {
                    padding: 15px !important;
                }
            }
        }
    }
}
