.page-body{
    .user-area-header {
        overflow: hidden;
         border-bottom:1px solid var(--brand-gold);          
    
        .profile-image {                        
            position: relative;
            cursor: pointer;            
            flex: 0 0 90px;
            max-width: 90px;
            @media only screen and (max-width: 1200px) {
                flex: 0 0 75px;
                max-width: 75px;
            }
            @media only screen and (max-width: 991px) {
                flex: 0 0 60px;
            max-width: 60px;
            }
            @media only screen and (max-width: 767px) {
                flex: 0 0 50px;
                max-width: 50px;
            }
            .avatar_wrapper {
                border-radius: 50%;
                display: block;
                overflow: hidden;
                border: 1px solid #dedede
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
            .update_photo {
                background: #fff;
                border-radius: 50%;
                position: absolute!important;
                top: 7px;
                right: 0;
                padding: 0;
                z-index: 1;
                border: 1px solid var(--brand-gold);
                width: 32px;
                height: 32px;

                @media only screen and (max-width: 767px) {
                    top: 0px;
                    padding: 4px 5px;
                    height: 22px;
                    width: 21px;
                }
                svg {
                    display: block;
                    width: 15px;
                    height: 30px;
                    fill: var(--brand);
                    position: relative;
                    display: table;
                    margin: 0 auto;
                    @media only screen and (max-width: 767px) {
                        width: 10px;
                        height:12px;
                    }
                }
            }
        }

        .profile-info {            
            padding: 0 27px;
            color: #dedede;
            flex: 0 0 calc(100% - 115px);
            max-width: calc(100% - 115px);
            h3{
                margin:0;
                font-size:14px;

            }
            @media only screen and (max-width: 1200px) {
                flex: 0 0 calc(100% - 100px);
                max-width: calc(100% - 100px);
                padding: 0 15px;
            }
            @media only screen and (max-width: 991px) {
                padding: 0 10px;
                flex: 0 0 calc(100% - 80px);
                max-width: calc(100% - 80px);
            }
            @media only screen and (max-width: 767px) {
                flex: 0 0 calc(100% - 50px);
                max-width: calc(100% - 50px);
            }    
            b{
                font-size: 12px;
            }    
        }  
        .profile-stats{
             .item-label{
                    font-size: 11px;
                    line-height: 1;
                }
                .item-value{
                    font-size: 25px;
                    line-height: 1;
                    padding-left: 3px;

                }
        }  
        .block-right{
            padding: 10px 0;
        }    
    }

}