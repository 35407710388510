.page-body {
    header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1001;
        width: 100%;
        padding: 25px 0;

        ul {
            padding: 20px 0;
        }

        .burger-menu {
            rect {
                fill: var(--primary-orange);
            }
        }

        .header-link {
            color: var(--dark);

            svg {
                path {
                    transition: all 0.2s;
                }
            }

            .header-link-text {
                transition: all 0.2s;
            }

            &:hover {
                color: var(--primary-orange);
            }

            &.logout-btn {
                svg {
                    path {
                        transition: all 0.2s;
                        stroke: var(--white);
                    }
                }

                &:hover {
                    svg {
                        path {
                            stroke: var(--primary-orange) !important;
                        }
                    }
                }
            }
        }

        .header-prices {
            color: var(--primary-orange);
        }

        .header-price {
            color: var(--primary-orange);
        }

        .user-icon {
            &:hover {
                path {
                    fill: var(--primary-orange);
                }
            }

            path {
                fill: var(--dark);
            }
        }

        .cart-icon {
            path {
                stroke: var(--dark);
            }
        }

        .anthology-logo {
            path {
                fill: var(--dark);
            }
        }

        &.orange-links {
            .header-link {
                color: var(--dark) !important;
            }

            .header-price {
                color: var(--primary-orange) !important;
            }

            .user-icon {
                path {
                    fill: var(--dark);
                }
            }

            .cart-icon {
                path {
                    stroke: var(--dark);
                }
            }

            .anthology-logo {
                path {
                    fill: var(--dark);
                }
            }

            .burger-menu {
                rect {
                    fill: var(--primary-orange);
                }
            }
            .logout-btn {
                svg {
                    path {
                        transition: all 0.2s;
                        stroke: var(--dark);
                    }
                }
            }
        }

        &.white-links {
            .header-prices {
                color: var(--white);
            }

            .header-link {
                &:hover {
                    color: var(--primary-orange) !important;
                }

                color: var(--white) !important;
            }

            .user-icon {
                &:hover {
                    path {
                        fill: var(--primary-orange);
                    }
                }

                path {
                    fill: var(--white);
                }
            }

            .cart-icon {
                path {
                    stroke: var(--white);
                }
            }

            .anthology-logo {
                path {
                    fill: var(--white);
                }
            }

            .burger-menu {
                &:hover {
                    rect {
                        transition: all 0.2s;
                        fill: var(--primary-orange);
                    }
                }

                rect {
                    fill: var(--white);
                }
            }
        }

        .anthology-logo {
            svg {
                width: 135px;

                @media (max-width: 767px) {
                    width: 100px;
                }
            }
        }

        .right-menu-nav {
            svg {
                vertical-align: sub;
            }

            .header-price {
                @media (max-width: 435px) {
                    display: block;
                }
            }
        }

        .cart-link {
            transition: none;

            span {
                transition: all 0.2s;
            }

            .cart-icon {
                path {
                    transition: all 0.2s;
                }
            }

            &:hover {
                .header-prices {
                    color: var(--primary-orange) !important;
                }

                color: var(--primary-orange) !important;

                .cart-icon {
                    path {
                        stroke: var(--primary-orange);
                    }
                }
            }
        }

        &.active-nav {
            .header-link {
                color: var(--primary-orange) !important;
            }

            .user-icon {
                path {
                    fill: var(--primary-orange);
                }
            }

            .anthology-logo {
                path {
                    fill: var(--primary-orange);
                }
            }

            .cart-link {
                color: var(--primary-orange);

                .cart-icon {
                    path {
                        stroke: var(--primary-orange);
                    }
                }
            }

            .burger-menu {
                opacity: 0;
                display: none;
                pointer-events: none;
            }

            .close-slider-btn {
                opacity: 1;
                display: inline-block;
            }
        }

        li {
            list-style: none;
        }

        .burger-menu,
        .close-slider-btn {
            cursor: pointer;
        }

        .close-slider-btn {
            opacity: 0;
            display: none;
        }
    }

    &.scrolled {
        header {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            background-color: rgba(0, 0, 0, 0.9);

            .header-prices {
                color: var(--primary-orange) !important;
            }

            .header-link {
                color: var(--primary-orange) !important;
            }

            .user-icon {
                path {
                    fill: var(--primary-orange);
                }
            }

            .cart-link {
                color: var(--primary-orange);

                .cart-icon {
                    path {
                        stroke: var(--primary-orange);
                    }
                }
            }

            .anthology-logo {
                path {
                    fill: var(--primary-orange);
                }
            }

            .burger-menu {
                rect {
                    fill: var(--primary-orange);
                }
            }

            .logout-btn {
                svg {
                    path {
                        stroke: var(--primary-orange);
                    }
                }
            }
        }
    }

    .slider-homepage {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: 1000;
        opacity: 0;
        pointer-events: none;
        transition: all ease-in 0.5s;
        padding: 0;
        opacity: 0;

        .video-overlay {
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 95%),
                    linear-gradient(
                        to top,
                        #000 0,
                        rgba(0, 0, 0, 0.738) 9.5%,
                        rgba(0, 0, 0, 0.541) 17%,
                        rgba(0, 0, 0, 0.382) 23.5%,
                        rgba(0, 0, 0, 0.278) 28.2%,
                        rgba(0, 0, 0, 0.194) 32.5%,
                        rgba(0, 0, 0, 0.126) 36.5%,
                        rgba(0, 0, 0, 0.075) 40.1%,
                        rgba(0, 0, 0, 0.042) 43.05%,
                        rgba(0, 0, 0, 0.021) 45.5%,
                        rgba(0, 0, 0, 0.008) 47.5%,
                        rgba(0, 0, 0, 0.002) 49.1%,
                        rgba(0, 0, 0, 0) 50%
                    );
            }
        }

        .swiper-slide {
            position: relative;
            transform: scale(0.85);
            transition: transform linear 1s;

            a {
                z-index: 5;
                position: relative;
                width: 100%;
                height: 100%;
                display: block;

                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 1);
                    opacity: 0.5;
                    transition: opacity linear 1s;
                    z-index: 1;
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(133deg, #908071 0, #7c5131 100%);
                    mix-blend-mode: hue;
                }
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 1;
            }

            video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 1;
            }

            h4 {
                display: table;
                color: white;
                text-transform: uppercase;
                z-index: 10;
                font-family: "Museo Sans 700";
                position: absolute;
                white-space: nowrap;
                top: 50%;
                transform: translateY(-50%);
                transition: all linear 1s;
                transition-delay: 0s;
            }
        }

        .swiper-slide-active {
            transform: scale(1);

            h4 {
                left: 50%;
                right: 50%;
                transform: translate(-50%, -50%);
                font-size: 40px;
                white-space: nowrap;

                @media (max-width: 575px) {
                    font-size: 26px;
                }
            }
        }

        .swiper-slide-next {
            a {
                &::before {
                    opacity: 0.8;
                }
            }

            h4 {
                left: -60px;
                transform: translateY(-50%);

                @media (max-width: 991px) {
                    left: 15px;
                }
            }
        }

        .swiper-slide-prev {
            a {
                &::before {
                    opacity: 0.8;
                }
            }

            h4 {
                left: calc(100% - 60px);
                transform: translateY(-50%);
            }
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            h4 {
                @media (max-width: 575px) {
                    font-size: 18px;
                }
            }
        }

        .swiper-pagination {
            position: absolute;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            bottom: 20px;
            display: flex !important;
            justify-content: center !important;
            gap: 100px;

            @media (max-width: 580px) {
                top: calc(50% + 150px) !important;
                gap: 50px;
            }
        }

        @media (max-width: 580px) {
            .swiper-pagination .custom-bullet:nth-child(2n) .bullet-text {
                transform: translate(calc(-50% + 5px), 23px);
            }
        }

        .custom-bullet {
            background-color: black;
            color: white;
            border-radius: 50%;
            position: relative;
            border: 3px solid var(--primary-orange);
            width: 16px;
            height: 16px;
            cursor: pointer;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: var(--primary-orange);
        }

        .bullet-text {
            font-size: 12px;
            font-weight: 700;
            position: absolute;
            transform: translate(calc(-50% + 5px), -25px);
            width: max-content;
        }
    }

    .slider-homepage {
        &.show {
            opacity: 1;
            pointer-events: all;
        }
    }

    .overlay.show {
        opacity: 1;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        opacity: 0;
        transition: opacity 0.5s;
        pointer-events: none;
        z-index: 999;
    }

    &.navigation-open {
        .slider-homepage {
            opacity: 1;
            padding: 15vh 18vw;
        }

        .icon-counter {
            color: var(--primary-orange) !important;
        }

        .logout-btn {
            svg {
                path {
                    stroke: var(--primary-orange) !important;
                }
            }
        }
    }

    &.page-our-stories {
        .header-link {
            &:hover {
                color: var(--primary-orange) !important;
            }

            color: var(--white) !important;
        }

        .user-icon {
            &:hover {
                path {
                    fill: var(--primary-orange);
                }
            }

            path {
                fill: var(--white);
            }
        }

        .cart-icon {
            path {
                stroke: var(--white);
            }
        }

        .anthology-logo {
            path {
                fill: var(--white);
            }
        }

        .burger-menu {
            &:hover {
                rect {
                    fill: var(--primary-orange);
                }
            }

            rect {
                fill: var(--white);
            }
        }
    }
    &.page-careers,
    &.page-terms-conditions,
    &.page-my-account,
    &.page-cart,
    &.page-addresses,
    &.page-orders,
    &.page-login,
    &.page-register,
    &.error-page,
    &.page-check-out,
    &.product-page,
     &.page-privacy-policy {
        &:not(.scrolled) {
            #header {
                .header-link {
                    &.logout-btn {
                        svg {
                            path {
                                stroke: var(--dark);
                            }
                        }
                    }
                }

                .cart-link {
                    .icon-counter {
                        color: var(--dark);
                    }
                }
                
            }
        }
    }
}
