.page-body {
    .hero-wrapper {
        min-height: 100vh;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 2;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 95%),
                linear-gradient(to top,
                    rgba(0, 0, 0, 1) 0,
                    rgba(0, 0, 0, 0.738) 9.5%,
                    rgba(0, 0, 0, 0.541) 17%,
                    rgba(0, 0, 0, 0.382) 23.5%,
                    rgba(0, 0, 0, 0.278) 28.2%,
                    rgba(0, 0, 0, 0.194) 32.5%,
                    rgba(0, 0, 0, 0.126) 36.5%,
                    rgba(0, 0, 0, 0.075) 40.1%,
                    rgba(0, 0, 0, 0.042) 43.05%,
                    rgba(0, 0, 0, 0.021) 45.5%,
                    rgba(0, 0, 0, 0.008) 47.5%,
                    rgba(0, 0, 0, 0.002) 49.1%,
                    rgba(0, 0, 0, 0) 50%);
        }

        video,
        .hero-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }

        .hero-img-text-wrapper {
            position: absolute;
            bottom: 3%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
            width: 100%;
        }

        .elixir {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
        }

        .bottom-right-img {
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 3;
            transform: translate(-25%, 20%);
            width: 550px;

            @media (max-width: 1200px) {
                width: 350px;
            }

            @media (max-width: 745px) {
                width: 250px;
            }

            @media (max-width: 767px) {
                width: 120px;
            }
        }

        .bottom-left-img {
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 3;
            transform: translateY(20%);
            width: 550px;

            @media (max-width: 1200px) {
                width: 350px;
            }

            @media (max-width: 1065px) {
                width: 250px;
            }

            @media (max-width: 767px) {
                width: 120px;
            }
        }

        .imgs {
            position: absolute;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 500px;
            z-index: 4;
        }

        .center-right-img {
            z-index: 3;
            height: 100%;
            max-height: 524px;
        }

        .center-left-img {
            z-index: 3;
            height: 100%;
            max-height: 524px;
        }
    }

    .hero-with-slider-wrapper {
        height: 100vh;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            background: linear-gradient(180deg, var(--white-light) 0%, rgba(237, 230, 218, 0) 100%);
            top: 0;
            left: 0;
            height: 50%;
            width: 100%;
            opacity: 1;
        }

        &::before {
            content: "";
            position: absolute;
            background: linear-gradient(133deg, #908071 0%, #7c5131 100%);
            mix-blend-mode: hue;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.85;
        }

        .guardian-msg-wrapper {
            position: absolute;
            top: 120px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
            width: 100%;
            max-width: 850px;

            .right-slider-arrow {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }

            .left-slider-arrow {
                position: absolute;
                top: 0;
                right: 30px;
                cursor: pointer;
            }

            .image-wrapper {
                position: relative;
                transform: translateX(50px);
                @media (max-width: 991px) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::before {
                    content: "";
                    position: absolute;
                    background: linear-gradient(133deg, #908071 0%, #7c5131 100%);
                    mix-blend-mode: hue;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
            }

            .message-card {
                position: relative;
                background: var(--white-light-2);
                box-shadow: 0px 4px 40px 0px var(--dark-3);
                padding: 40px 33px 20px 100px;
                z-index: 1;

                @media (max-width: 991px) {
                    padding: 25px;
                }
            }
        }

        .guardians-swiper {
            height: 100%;

            .swiper-button-prev {
                position: absolute;
                top: 0;
                right: 0 !important;
                left: auto;
                transform: translateY(-100%);

                @media (max-width: 991px) {
                    right: 30px !important;
                }
            }

            .swiper-button-next {
                position: absolute;
                top: 0;
                right: 30px;
                transform: translateY(-100%);

                @media (max-width: 991px) {
                    right: 60px !important;
                }
            }
        }
    }

    .our-values-hero {
        position: relative;

        .elixir {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            height: 40vh;

            @media (max-width: 768px) {
                height: 50vw;
                top: 40%;
            }
        }

        .hero-text {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            width: 100%;

            @media (max-width: 768px) {
                width: 100%;
                top: 75%;
            }
        }
    }
}
