


:root {

    --error:#F00D0D;
    --red: #AF0303;
    --orange: #fd7e14;
    --primary-orange: #F18F3F;
    --yellow: #ffc107;
    --green: #28a745;

    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --white-ignore-theme: #fff;
    --white-light: #EDE6DA;
    --white-light-2: #f2eee8;

    --dark: #000;
    --dark-2: #0F0000;
	--dark-3: rgba(0, 0, 0, 0.25);
	--dark-4: #00000080;

    --gray: #222324;
    --gray-2: rgba(255, 255, 255, 0.3);

    --swiper-navigation-color:#ffffff;
    --swiper-theme-color: #ffffff;
    --primary: #0D68F0;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #AF0303;
    --light: #f8f9fa;
    --light-grey: #D9D9D9;

    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


	--containerLargeWidth : 1700px;
	--containerWidth : 1170px;
    --containerMediumWidth : 830px;
	--containerSmallWidth : 600px;
    --containerExtraSmallWidth : 485px;


    --footer_height:70px;
    --header_height :90px;
    @media only screen and (max-width: 1400px) {
        --footer_height :70px;
    }
    @media only screen and (max-width: 1200px) {
        --header_height :67px;
        --footer_height :55px;
    }
    @media only screen and (max-width: 991px) {
        --header_height :66px;
        --footer_height :70px;
    }
    @media only screen and (max-width: 767px) {
        --header_height :66px;
        --footer_height :333px;
    }
	--transition_fast:0.2s;
}
