.page-body{
	.thank-you {
    margin-top: 50px;
    margin-bottom: 100px;
    @media only screen and (max-width: 991px) {
        margin-top: 30px;
        margin-bottom: 70px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .thank-you-wrapper {
        padding: 40px 20px;
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
        filter: drop-shadow(-20px 1px 20px rgba(0, 0, 0, .2));
        &:after {
            position: absolute;
            content: "";
            bottom: -48px;
            height: 55px;
            width: 100%;
            left: 0;
            background-image: url('/assets/frontend/default/img/receipt_bg.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
             @media only screen and (max-width: 380px) {
                bottom: -38px;
            }
        }
        @media only screen and (max-width: 991px) {
            padding: 42px 62px;
        }
        @media only screen and (max-width: 767px) {
            padding: 22px 42px;
        }
        @media only screen and (max-width: 575px) {
            padding: 20px;
        }
        .details_content {
            margin: 0 auto;
            padding: 15px;
            @media only screen and (max-width: 991px) {}
            @media only screen and (max-width: 767px) {}
            @media only screen and (max-width: 380px) {
                padding: 15px 0;
            }
        }
        .download-receipt{
        	svg{
        		fill:var(--white);
        		width: 20px;
    			height: 20px;
        	}
        }
        h1 {
            font-size: 32px;
            text-align: center;
            margin-bottom: 34px;
            @media only screen and (max-width: 1440px) {
                font-size: 28px;
            }
            @media only screen and (max-width: 991px) {
                font-size: 24px;
                margin-bottom: 24px;
            }
            @media only screen and (max-width:767px) {
                font-size: 20px;
                margin-bottom: 14px;
            }
        }
        .check-img {
            margin: 0 auto;
            display: block;
            font-size: 16px;
            max-width: 130px;
            margin-bottom: 34px;
            @media only screen and (max-width: 991px) {
                max-width: 100px;
                margin-bottom: 24px;
            }
            @media only screen and (max-width:767px) {
                max-width: 70px;
                font-size: 14px;
                margin-bottom: 14px;
            }
        }
	        .download-receipt {
	            margin-bottom: 34px;
	            @media only screen and (max-width: 991px) {
	                margin-bottom: 24px;
	            }
	            @media only screen and (max-width:767px) {
	                margin-bottom: 14px;
	            }
	        }
	    }
	}
}