.timeline-with-icons {
    border-left: 1px solid #2d2d2d;
    position: relative;
    list-style: none;
    .timeline-item {
        position: relative;
    }

    .timeline-item:after {
        position: absolute;
        display: block;
        top: 0;
    }

    .timeline-icon {
        position: absolute;
        left: -48px;
        
        border-radius: 50%;
        height: 31px;
        width: 31px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            width:22px;
            height:auto;
            fill:#fff;
        }
    }
}
