.page-body {
    .product-item-caption {
        p {
            margin-bottom: 20px !important;
        }
    }

    .add-to-cart-box {
        position: fixed;
        top: 50%;
        padding: 0 20px 20px 20px;
        right: 25px;
        display: inline-block;
        transform: translateY(-50%);
        min-width: 300px;
        display: flex;
        flex-direction: column;
        z-index: 25;
        transition: 0.2s linear all;

        background: var(--white-light-2);
        padding: 25px 50px 35px;
        box-shadow: 0 4px 40px 0 var(--dark-3);
        @media (max-width: 991px) {
            bottom: 0px;
            width: 100%;
            top: unset;
            transform: unset;
            min-width: 100%;
            right: 0px;
            padding: 5px;
            .quantity-add-cart {
                display: flex;
                justify-content: space-between;
                .form-group {
                    margin-bottom: 0;
                }
            }
        }
        form {
            .form-group {
                margin-bottom: 16px;
            }
        }
    }

    .btn-cart {
        background-color: var(--primary-orange);
    }

    .our-elixirs {
        padding-bottom: 100px;
        z-index: 10;
        position: relative;
        background: var(--white-light);

        @media (max-width: 991px) {
            padding-bottom: 50px;
        }

        .right-card,
        .left-card {
            background: var(--white-light-2);
            padding: 25px 50px 35px;
            box-shadow: 0px 4px 40px 0px var(--dark-3);
            transform: translateY(-50px);
            height: 100%;
            position: relative;

            @media (max-width: 1200px) {
                padding: 20px 40px;
            }

            @media (max-width: 991px) {
                padding: 20px 20px;
            }
        }

        .left-card {
            .our-elixirs-text {
                padding-top: 6rem;
            }
        }
        .right-card {
            img {
                min-height: 450px;

                @media (max-width: 575px) {
                    min-height: 310px;
                }
            }

            @media (max-width: 575px) {
                // min-height: 40vh;
            }
        }

        .bee-img {
            vertical-align: bottom;
        }

        span {
            svg:not(.arrow) {
                opacity: 0.1;
            }
        }

        .purchase-btn {
            background: var(--light-grey);
            border-radius: 60px;
            font-size: 12px;
            border: 1px solid var(--primary-orange);
            padding: 10px 16px;
            letter-spacing: 2.1px;
            text-transform: uppercase;
            display: inline-block;
        }

        .mountain-img {
            z-index: 0;
            bottom: -80px;
            right: -60px;
            width: 350px;

            @media (max-width: 767px) {
                width: 250px;
            }
        }

        .bird-wing-img {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0.1;
            width: 380px;
            transform: translateX(-50%);
            z-index: -1;

            @media (max-width: 991px) {
                width: 250px;
            }
        }

        .flower {
            top: 0;
            left: 0;
            transform: translate(-60%, -30%);
            width: 450px;
            z-index: -1;

            @media (max-width: 1300px) {
                width: 250px;
            }

            @media (max-width: 991px) {
                transform: translateX(-50%) rotate(90deg);
            }
        }

        .flower-left-card {
            bottom: 0;
            left: 40px;
            transform: translateY(90%);
            width: 550px;
            z-index: -1;

            @media (max-width: 991px) {
                transform: translate(37%, 90%);
                width: 250px;
            }
        }

        .plant-left-card {
            top: 10px;
            left: 15%;
            width: 100px;
            z-index: -1;

            @media (max-width: 991px) {
                svg {
                    width: 85px;
                }
            }
        }

        .swiper-next,
        .swiper-prev {
            position: absolute;
            top: 25px;
            z-index: 2;
        }

        .swiper-next {
            right: 80px;
        }

        .swiper-prev {
            right: 40px;
        }

        .elixirs-wrapper {
            padding: 0 35px;

            @media (max-width: 991px) {
                padding: 0;
            }

            .col-left-card {
                max-width: 28%;
                flex: 0 0 28%;
                // max-height: 600px;

                @media (max-width: 1440px) {
                    max-width: 38%;
                    flex: 0 0 38%;
                }

                @media (max-width: 1200px) {
                    max-width: 40%;
                    flex: 0 0 40%;
                }

                @media (max-width: 991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .col-right-card {
                max-width: 72%;
                flex: 0 0 72%;

                .swiper-button-disabled {
                    opacity: 0.5;
                }

                @media (max-width: 1440px) {
                    max-width: 62%;
                    flex: 0 0 62%;
                }

                @media (max-width: 1200px) {
                    max-width: 60%;
                    flex: 0 0 60%;
                }

                @media (max-width: 991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
        }

        @media (max-width: 991px) {
            padding-bottom: 0px;
        }
    }
    @media (min-width: 992px) {
        .imageproduct-col {
            height: 555px;
            &.even-product {
                order: 2;
            }
        }
    }
    .basic-zoom-in {
        transition: all 0.6s ease-out;
        &:hover {
            transform: scale(1.1);
        }
    }

    &.product-page {
        @media (max-width: 767px) {
            .hero-product {
                img {
                    margin-top: 79px;
                }
            }
        }
    
    }
}
