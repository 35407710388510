footer {
    z-index: 15;
    position: relative;
    padding: 30px 0;
    font-size: 12px;
    border-top: 1px solid var(--gray-2);

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    .footer-link {
        &:hover {
            transition: linear 0.2s;
            color: var(--primary-orange);
        }
    }

    .social-link {
        margin-right: 27px;

        @media (max-width: 1200px) {
            margin-right: 20px;
        }

        // @media (max-width: 767px) {
        //     margin-right: 12px;
        // }

        &:hover {
            svg {
                path {
                    transition: linear 0.2s;
                    fill: var(--primary-orange);

                }
            }
        }
    }
}
