.page-body {
    .contact-form {
        .form-group {
            input,.form-control {
                border: 1px solid var(--gray);
                box-shadow: 0px 4px 40px 0px var(--dark-3);
                outline: none;
                height: 61px;
                padding-left: 17px;
                background: transparent;
                color: var(--white);

                &::placeholder {
                    color: var(--gray-2);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                }

                &:focus {
                    border: 2px solid var(--primary-orange);
                }
            }

            textarea {
                border: 1px solid var(--gray);
                box-shadow: 0px 4px 40px 0px var(--dark-3);
                outline: none;
                height: 104px;
                padding-left: 10px;
                background: transparent;
                color: var(--white);

                &::placeholder {

                    color: var(--gray-2);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    padding-left: 5px;
                    padding-top: 10px;
                }

                &:active,
                &:focus {
                    transition: all linear .2s;
                    border: 2px solid var(--primary-orange);
                }
            }

            label {
                position: absolute;
                left: 16px;
                top: 12px;
                text-transform: uppercase;
                font-size: 12px;
                transition: transform 0.3s ease, opacity 0.3s ease;
            }

            &.filled {
                label {
                    opacity: 0;
                }
            }


        }

        padding-bottom: 60px;
    }

    .contact-info-wrapper {
        padding-top: 100px;
        .contact-info {
            background: var(--gray);
            box-shadow: 0px 4px 40px 0px var(--dark-3);
            padding: 30px;
            transform: translateY(-80px);
            color: var(--white);

            @media (max-width: 991px) {
                transform: translateY(-40px);
                margin: 25px 0;
            }

            a {
                svg {
                    vertical-align: top;
                }
            }

            .ch {
                height: 244px;
            }

            .social-link {
                &:hover {
                    color: var(--primary-orange);

                    svg {
                        path {
                            fill: var(--primary-orange);
                        }
                    }

                }
            }
        }

        h6 {
            color: var(--primary-orange);
            font-size: 20px;
        }
    }

    .directions-contact {
        bottom: 0;

        @media (max-width: 991px) {
            position: relative !important;
            margin-top: 20px;
        }
    }

    .shop-adress {
        max-width: 170px;
    }

    .info-link {
        &:hover {
            color: var(--primary-orange);
        }
    }

    &.page-belong {
        padding-top: 0;
    }

    .general-enquires{
        p{
            margin-bottom: 2px;
        }
    }
}
