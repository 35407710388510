@font-face {
    font-family: 'Museo Sans 500';
    src: url('fonts/Museo/MuseoSans-500.eot');
    src: url('fonts/Museo/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('fonts/Museo/MuseoSans-500.woff2') format('woff2'),
        url('fonts/Museo/MuseoSans-500.woff') format('woff'),
        url('fonts/Museo/MuseoSans-500.ttf') format('truetype'),
        url('fonts/Museo/MuseoSans-500.svg#MuseoSans-500') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('fonts/Museo/MuseoSans-700.eot');
    src: url('fonts/Museo/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('fonts/Museo/MuseoSans-700.woff2') format('woff2'),
        url('fonts/Museo/MuseoSans-700.woff') format('woff'),
        url('fonts/Museo/MuseoSans-700.ttf') format('truetype'),
        url('fonts/Museo/MuseoSans-700.svg#MuseoSans-700') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('fonts/Museo/MuseoSans-300.eot');
    src: url('fonts/Museo/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('fonts/Museo/MuseoSans-300.woff2') format('woff2'),
        url('fonts/Museo/MuseoSans-300.woff') format('woff'),
        url('fonts/Museo/MuseoSans-300.ttf') format('truetype'),
        url('fonts/Museo/MuseoSans-300.svg#MuseoSans-300') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('fonts/Museo/MuseoSans-100.eot');
    src: url('fonts/Museo/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('fonts/Museo/MuseoSans-100.woff2') format('woff2'),
        url('fonts/Museo/MuseoSans-100.woff') format('woff'),
        url('fonts/Museo/MuseoSans-100.ttf') format('truetype'),
        url('fonts/Museo/MuseoSans-100.svg#MuseoSans-100') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Baskerville Bold';
    src: url('fonts/Baskerville/BaskervilleBold.eot');
    src: url('fonts/Baskerville/BaskervilleBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville/BaskervilleBold.woff2') format('woff2'),
        url('fonts/Baskerville/BaskervilleBold.woff') format('woff'),
        url('fonts/Baskerville/BaskervilleBold.ttf') format('truetype'),
        url('fonts/Baskerville/BaskervilleBold.svg#BaskervilleBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville 300';
    src: url('fonts/Baskerville/BaskervilleLight.eot');
    src: url('fonts/Baskerville/BaskervilleLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville/BaskervilleLight.woff2') format('woff2'),
        url('fonts/Baskerville/BaskervilleLight.woff') format('woff'),
        url('fonts/Baskerville/BaskervilleLight.ttf') format('truetype'),
        url('fonts/Baskerville/BaskervilleLight.svg#BaskervilleLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville Light';
    src: url('fonts/Baskerville/Baskerville.eot');
    src: url('fonts/Baskerville/Baskerville.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville/Baskerville.woff2') format('woff2'),
        url('fonts/Baskerville/Baskerville.woff') format('woff'),
        url('fonts/Baskerville/Baskerville.ttf') format('truetype'),
        url('fonts/Baskerville/Baskerville.svg#Baskerville') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville Normal';
    src: url('fonts/Baskerville/Baskerville-Normal.eot');
    src: url('fonts/Baskerville/Baskerville-Normal.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville/Baskerville-Normal.woff2') format('woff2'),
        url('fonts/Baskerville/Baskerville-Normal.woff') format('woff'),
        url('fonts/Baskerville/Baskerville-Normal.ttf') format('truetype'),
        url('fonts/Baskerville/Baskerville-Normal.svg#Baskerville-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Harshita';
    src: url('fonts/Harshita/Harshita.eot');
    src: url('fonts/Harshita/Harshita.eot?#iefix') format('embedded-opentype'),
        url('fonts/Harshita/Harshita.woff2') format('woff2'),
        url('fonts/Harshita/Harshita.woff') format('woff'),
        url('fonts/Harshita/Harshita.ttf') format('truetype'),
        url('fonts/Harshita/Harshita.svg#Harshita') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Monotype Corsiva';
    src: url('fonts/MonotypeCorsiva/MonotypeCorsiva.eot');
    src: url('fonts/MonotypeCorsiva/MonotypeCorsiva.eot?#iefix') format('embedded-opentype'),
        url('fonts/MonotypeCorsiva/MonotypeCorsiva.woff2') format('woff2'),
        url('fonts/MonotypeCorsiva/MonotypeCorsiva.woff') format('woff'),
        url('fonts/MonotypeCorsiva/MonotypeCorsiva.ttf') format('truetype'),
        url('fonts/MonotypeCorsiva/MonotypeCorsiva.svg#MonotypeCorsiva') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

.page-body {
    .font-size-12 {
        font-size: 12px;
    }

    .font-size-20 {
        font-size: 20px;
        line-height: 1.6;
    }

    .font-size-14 {
        font-size: 14px;
    }

    .font-size-16 {
        font-size: 16px;
    }

    .font-size-18 {
        font-size: 18px;
    }

    .font-size-24 {
        font-size: 24px;

        @media only screen and (max-width: 1200px) {
            font-size: 22px;
        }

        @media only screen and (max-width: 991px) {

            font-size: 20px;
        }
    }

    .font-size-26 {
        font-size: 26px;

        @media only screen and (max-width: 1200px) {
            font-size: 22px;
        }

        @media only screen and (max-width: 991px) {

            font-size: 18px;
        }
    }

    .font-size-28 {
        font-size: 28px;

        @media only screen and (max-width: 1200px) {
            font-size: 24px;
        }

        @media only screen and (max-width: 991px) {

            font-size: 22px;
        }
    }

    .font-size-30 {
        font-size: 30px;

        @media only screen and (max-width: 1200px) {
            font-size: 26px;
        }

        @media only screen and (max-width: 991px) {

            font-size: 22px;
        }
    }

    .font-size-31 {
        font-size: 31px;

        @media only screen and (max-width: 1200px) {
            font-size: 27px;
        }

        @media only screen and (max-width: 991px) {

            font-size: 24px;
        }
    }

    .font-size-33 {
        font-size: 33px;

        @media only screen and (max-width: 1400px) {
            font-size: 29px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 25px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 21px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 17px;
        }
    }

    .font-size-34 {
        font-size: 34px;

        @media only screen and (max-width: 1400px) {
            font-size: 30px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 26px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 22px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    .font-size-36 {
        font-size: 36px;

        @media only screen and (max-width: 1400px) {
            font-size: 32px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 28px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 24px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
    }

    .font-size-40 {
        font-size: 40px;

        @media only screen and (max-width: 1400px) {
            font-size: 36px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 32px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 28px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
    }

    .font-size-45 {
        font-size: 45px;

        @media only screen and (max-width: 1400px) {
            font-size: 40px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 36px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 32px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 28px;
        }
    }

    .font-size-64 {
        font-size: 64px;

        @media only screen and (max-width: 1600px) {
            font-size: 56px;
        }

        @media only screen and (max-width: 1400px) {
            font-size: 52px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 44px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 38px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 32px;
        }
    }

    .font-size-72 {
        font-size: 72px;

        @media only screen and (max-width: 1600px) {
            font-size: 64px;
        }

        @media only screen and (max-width: 1400px) {
            font-size: 58px;
        }

        @media only screen and (max-width: 1200px) {
            font-size: 44px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 40px;
        }
    }

    .font-size-96 {
        font-size: 96px;

        @media only screen and (max-width: 1270px) {
            font-size: 72px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 68px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 54px;
        }

        @media only screen and (max-width: 575px) {
            font-size: 34px;
        }
    }

    .text-blue {
        color: var(--blue-dark) !important;
    }

    .text-dark {
        color: var(--blue-dark) !important;
    }

    .text-white {
        color: var(--white) !important;
    }

    .text-gray {
        color: var(--gray) !important;
    }

    .text-gray-4 {
        color: var(--gray-4) !important;
    }


    .text-black {
        color: var(--dark) !important;
    }

    .bg-black {
        background: var(--dark);
    }

    .bg-black-2 {
        background: var(--dark-2);
    }

    .bg-light-white {
        background: var(--white-light);
    }

    .text-primary-orange {
        color: var(--primary-orange) !important;
    }



    .bg-gray {
        background: var(--gray);
    }

    p {
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 575px) {
            margin-bottom: 15px;
        }
    }

    .font-alt {
        font-family: 'Monotype Corsiva' !important;
    }

    // Fonts
    .font-museo-regular {
        font-family: 'Museo Sans 300' !important;
    }

    .font-museo-semibold {
        font-family: 'Museo Sans 500';
    }

    .font-museo-bold {
        font-family: 'Museo Sans 700';
    }

    .font-museo-thin {
        font-family: 'Museo Sans 100';
    }

    .font-baskerville-regular {
        font-family: 'Baskerville Normal';
    }

    .font-baskerville-thin {
        font-family: 'Baskerville 300';
        letter-spacing: -2px;
    }

    .font-harshita {
        font-family: 'Harshita' !important;
    }


    b,
    strong {
        font-family: 'Museo Sans 700';
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Museo Sans 700';
    }

    .h1,
    h1 {
        line-height: 1.2;
        font-size: 50px;
        margin-bottom: 20px;

    }

    .h2,
    h2 {
        font-size: 36px;
        line-height: 1.2;
        margin-bottom: 20px;

    }

    .h3,
    h3 {
        font-size: 28px;
        line-height: 1.25;
        margin-bottom: 20px;

    }

    .h4,
    h4 {
        font-size: 20px;
        line-height: 1.2;
    }

    .h5,
    h5 {
        font-size: 16px;
        line-height: 1.2;
    }

    .h6,
    h6 {
        font-size: 14px;
        line-height: 1.2;
    }

 

    .line-height-1 {
        line-height: 1;
    }

    .line-height-2 {
        line-height: 1.2;
    }

}
