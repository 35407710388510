.page-body {
    .side-cart-modal {
        padding-right: 0!important;
        &.show {
            .modal-dialog {
                -ms-transform: translate(0%, 0%)!important;
                transform: translate(0%, 0%)!important;
            }
        }
        .modal-dialog {
            max-width: 450px;
            width: 100%;
            margin: 0;
            float: right;
            transform: translate(100%, 0%)!important;
            .modal-content {
                border: none;
                border-radius: 0;
                background-color: var(--dark);
                color: var(--white);
            }
            .close-modal {
                padding: 0.5rem!important;
                position: absolute;
                top: 10px;
                right: 10px;
                min-width: inherit;
                z-index: 10;
                svg {
                    width: 18px;
                    height: 18px;
                    fill: var(--white);
                }
            }
            .modal-body {
                padding: 0;
                border-radius: 0;
                min-height: 100vh;
                .notifications-header {
                    background: var(--dark);
                    padding: 12px 25px;
                    @media only screen and (max-width: 991px) {
                        padding: 12px 20px;
                    }
                    @media only screen and (max-width: 767px) {
                        padding: 12px 15px;
                    }
                    h3 {
                        margin: 0;
                        padding: 14px 0;
                        width: calc(100% - 50px);
                        @media only screen and (max-width: 991px) {
                            width: calc(100% - 45px);
                            padding: 10px 0;
                        }
                        @media only screen and (max-width: 767px) {
                            margin: 0;
                            padding: 9px 0;
                        }
                    }
                }
                .notifications-body {
                    padding: 0 25px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 20px;
                    }
                    @media only screen and (max-width: 767px) {
                        padding: 0 15px;
                    }
                    .delete-all, .read-all {
                        white-space: nowrap;
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }
                    }
                    .notification {
                        &.unread {
                            &:before {
                                position: absolute;
                                top: 4px;
                                left: 26px;
                                content: "Unread";
                                background: var(--brand);
                                border-radius: 4px;
                                color: #fff;
                                font-size: 10px;
                                padding: 3px;
                                line-height: 1;
                            }
                        }
                        .remove {
                            width: 10px;
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            svg {
                                fill: var(--danger);
                            }
                        }
                        .remove-selected {
                            width: 16px;
                            svg {
                                fill: var(--danger);
                            }
                        }
                        .read-selected {
                            width: 16px;
                            svg {
                                fill: var(--brand);
                            }
                        }
                        margin: 0 -25px;
                        padding: 25px;
                        &:nth-child(even) {}
                        &:not([style*="display:none"]):not([style*="display: none"]):last-child {
                            .actions {}
                        }
                        .pic {
                            width: 60px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin-top: 3px;
                            float: left;
                        }
                        .content {
                            width: calc(100% - 60px);
                            float: left;
                            text-align: left;
                            h4 {
                                font-size: 16px;
                                margin-bottom: 7px;
                            }
                            .title {
                                opacity: 0.5;
                                font-size: 14px;
                                margin: 0;
                            }
                            .date {
                                opacity: 0.5;
                                font-size: 12px;
                                margin: 0;
                            }
                        }
                        .actions {
                            font-size: 12px;
                            padding: 5px 0;
                            padding-bottom: 24px;
                            .time {
                                opacity: .5;
                                float: left;
                            }
                            .btn-link {
                                text-transform: uppercase;
                                color: #202020;
                                float: right;
                                padding: 0;
                                font-size: 12px;
                                letter-spacing: 0.2px;
                                &:hover {
                                    color: var(--brand);
                                }
                            }
                        }
                    }
                }
                .remove-all {
                    color: #202020;
                    svg {
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                    }
                    label {
                        cursor: pointer;
                    }
                }
                .notifications-toggle {
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        top: 50%;
                        left: 60px;
                        right: 60px;
                        transform: translateY(-50%);
                        height: 1px;
                        background: #c8c8c8;
                    }
                    .form-group {
                        display: table;
                        margin: 0 auto;
                        background: #fff;
                        position: relative;
                        padding: 7px 35px;
                        .option {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
   
}