.page-body {
    .account-nav {
        position: relative;

        .account-nav-item {
            margin: 0;
            padding: 5px 10px;
            width: auto;
            margin-right: 25px;

            @media only screen and (max-width: 1200px) {
                padding: 10px;
                margin-right: 30px;
            }

            @media only screen and (max-width: 991px) {
                padding: 10px 15px;
                margin-right: 20px;
            }

            @media only screen and (max-width: 767px) {
                padding: 10px;
                margin: 0;
            }

            @media only screen and (max-width: 575px) {
                padding: 10px 0px;
                margin: 0;
            }

            a {
                opacity: 1;
                padding: 0;
                position: relative;
                line-height: 1;
                display: block;
                font-size: 16px;
                color: var(--dark);

                @media only screen and (max-width: 991px) {
                    font-size: 14px;
                }

                &:after {
                    position: absolute;
                    content: "";
                    bottom: 10px;
                    height: 2px;
                    background: var(--primary-orange);
                    transition: all .4s;
                    width: 0;
                    left: 0;

                    @media only screen and (max-width: 991px) {
                        bottom: 5px;
                    }

                    @media only screen and (max-width: 767px) {
                        left: 0px;
                        right: 0px;
                    }
                }

                &.active,
                &:active,
                &:focus,
                &:hover {
                    color: var(--primary-orange) !important;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }

    }
}
