.page-body {
    .cart-overview {
        .rounded {
            border-radius: 10px !important;
        }

        .bg-white-light {
            background-color: rgba(255, 255, 255, .6);
        }

        .product-image {
            @media only screen and (max-width: 767px) {
                width: 100%;
                max-width: 120px;
            }
        }
    }

    .quantity-selector {
        background-color: var(--white);
        padding: 4px 10px;
        border-radius: 20px;

        .increase-qty,
        .decrease-qty {
            padding: 6px;
        }

        input[name="quantity"],
        input[name="quantity_multiple"] {
            max-width: 50px;
            min-width: 30px;
            font-size: 12px;
        }
    }


    .update-cart-submit {
        min-width: 32px;
        width: 32px;
        height: 32px;
        display: block;
        position: relative;

        svg {
            height: 18px;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }
    }

    .remove-from-cart-trigger {
        svg {
            fill: var(--red) !important;
        }
    }

    .input-box {
        border: 0;
        outline: none;
        text-align: center;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;

        &::-ms-clear {
            display: none;
        }
    }

    input.form-control.coupon {
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 8px;
        padding: 15px;
        height: 50px;
        padding-right: 50px;
        color: var(--brand) !important;

        &:valid,
        &:focus {
            border-color: var(--brand);
        }
    }

    .remove-coupon-trigger {
        position: absolute !important;
        top: 14px;
        right: 0px;

        svg {
            fill: var(--red) !important;
        }
    }

    .order-notes {
        label {
            display: none;
        }

        textarea {
            border: 2px solid #f2f2f2;
            border-radius: 8px;
            padding: 15px;
            min-height: 120px;

            &:focus {
                border-color: var(--brand);
            }
        }
    }

    .product-cart-table {
        th {
            border-top: 1px solid rgba(255, 165, 0, 0.8);
            border-bottom: 1px solid rgba(255, 165, 0, 0.8);
        }
    }

    .shopping-cart-wrapper {

        .col-price,
        .col-quantity {
            width: 150px;
            flex: 0 0 150px;
            text-align: center;

            @media (max-width:991px) {
                width: 50%;
                flex: 0 0 50%;
            }
        }
        .col-price{
            @media (max-width:991px) {
                text-align: end;
            }
           
        }

        .col-remove-from-cart {
            width: 80px;
            flex: 0 0 80px;
            text-align: center;
            @media (max-width:991px) {
                position: absolute;
                top: 10px;
                right: -15px;
            }
        }

        .form-group {
            margin-bottom: 0 !important
        }

        .cart-item,
        .shopping-cart-headers {
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 10px;
            background-color: rgba(255, 255, 255, .6);
        }

        .cart-item {
            .title {
                color: var(--dark);
                transition: .4s;

                &:hover {
                    color: var(--primary-orange);
                }
            }
        }

        .remove-cart-btn {
            &:hover {
                path {
                    stroke: var(--primary-orange);
                    transition: .4s;
                }
            }
        }
    }

}
