.page-body {
    .account-view.authForm {
        min-height: calc(100vh - 259px);

        // padding: 30px 0 60px;
        form {
            max-width: 500px;
            padding: 0 0 100px;
            margin: 0 auto;
            background: #fff;
            padding: 30px;
        }
    }

    .authForm {
        .authFormHeader {
            text-align: center;
            padding: 8px 0;

            .fingerprint-icon {
                border-radius: 50%;
                display: table;
                margin: 0 auto;
                background: var(--brand);
                width: 90px;
                height: 90px;

                svg {
                    height: 90px;
                    display: table;
                    margin: 0 auto;
                    width: 49px;
                }
            }

            .social-login {
                padding: 5px 0;

                li {
                    float: left;
                    padding: 0 20px;

                    @media only screen and (max-width: 1200px) {
                        padding: 0 15px;
                    }

                    @media only screen and (max-width: 991px) {
                        padding: 0 10px;
                    }

                    @media only screen and (max-width: 767px) {
                        padding: 0 5px;
                    }

                    a {
                        width: 50px;
                        height: 50px;
                        display: block;
                        border-radius: 50%;
                        border: 2px solid var(--brand);

                        &:hover {
                            background: var(--brand);
                        }

                        svg {
                            height: 22px;
                            width: 22px;
                            fill: var(--dark);
                        }
                    }
                }
            }

            p {
                font-size: 16px;
                color: var(--grey);
                padding: 46px 0 10px;

                @media only screen and (max-width: 1200px) {
                    padding: 30px 0 10px;
                    font-size: 14px;
                }

                @media only screen and (max-width: 991px) {
                    padding: 20px 0 10px;
                }

                @media only screen and (max-width: 767px) {
                    padding: 10px 0 5px;
                }
            }
        }

        form {
            .forgot-pass {
                position: absolute;
                right: 0;
                top: 100%;
                transform: translateY(-100%);
                padding: 5px;
                color: var(--grey);
                background: none;
                z-index: 2;
                margin: -1px 1px 0 0;

                &:hover {
                    color: var(--dark);
                }
            }

            .form-actions {
                padding: 20px 0 0;

                @media only screen and (max-width: 991px) {
                    padding: 15px 0 0;
                }

                @media only screen and (max-width: 767px) {
                    padding: 10px 0 0;
                }

                .btn {
                    padding: 21px 0;
                }

                .cta {
                    color: var(--grey);
                    font-size: 12px;
                    padding: 18px 0 0;
                    text-align: center;
                }
            }

            .form-group {
                position: relative;
                margin-bottom: 5px;
                background: none;
                padding: 0;

                label {
                    margin: 0;
                    color: var(--grey);
                    font-size: 12px;
                }

                .form-control {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #b2a998;
                    padding: 5px 0;
                    margin-bottom: 25px;

                    &:focus {
                        color: var(--dark);
                        border-color: var(--dark);
                    }

                }

                .parsley-error {
                    border-color: var(--error) !important;
                }

                .parsley-errors-list {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 100%;
                    background: none;
                    list-style: none;
                    color: var(--error);
                    z-index: 10;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;

                    @media only screen and (max-width: 1200px) {
                        font-size: 10px;
                    }

                    li {
                        padding: 0 5px;
                    }
                }
            }
        }
    }
}
