.blog-grid {
    padding: 150px 0;
    position: relative;
    background: url(../img/our-stories.jpg) var(--white-light);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg,rgba(0,0,0,0) 0,rgba(0,0,0,.3) 95%),linear-gradient(to bottom,rgba(0,0,0,1) 0,rgba(0,0,0,.738) 9.5%,rgba(0,0,0,.541) 17%,rgba(0,0,0,.382) 23.5%,rgba(0,0,0,.278) 28.2%,rgba(0,0,0,.194) 32.5%,rgba(0,0,0,.126) 36.5%,rgba(0,0,0,.075) 40.1%,rgba(0,0,0,.042) 43.05%,rgba(0,0,0,.021) 45.5%,rgba(0,0,0,.008) 47.5%,rgba(0,0,0,.002) 49.1%,rgba(0,0,0,0) 50%);
    }

   

    .blog-item {
        text-align: center;
        padding: 2.4em 1em;
        box-shadow: 0 3px 40px rgba(0, 0, 0, 0.2);
        min-height: 275px;
        background: linear-gradient(135deg,
                rgb(255, 255, 255) 0%,
                rgb(237, 230, 218) 100%);
        margin-bottom: 1.8rem;

        p {
            max-width: 80%;
            margin: auto;
            opacity: 0.75;
        }
    }

    .line {
        display: block;
        width: 30%;
        background-color: #d4b68e;
        height: 1px;
        margin: 0 auto;
        margin-bottom: 1em;
    }
}