body.auth-wrapper{
	min-height:100vh;
	position: relative;
    overflow-x: hidden;
    padding:0;
	&:before {
		background-position: top center;
		position:fixed;
	    background: url("/assets/frontend/default/img/bgs/2.jpg");
    	background-size: cover;

    	content: "";
	    position: absolute;
	    z-index: -1;
	    background-size: cover;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	} 
	
}

.auth-box-w{
	width: 417px;
	max-width: 90%;	    
	background-color: rgba(32 , 32 , 32 , 0.65);
	border-radius: 0;
	box-shadow: none;
	border: none;
	margin-left: 25px;
	border-radius: 10px; 
	color:#fff;
	padding: 22px 50px 22px 49px;
	top: 25vh;
    position: relative;
	@media only screen and (max-width: 991px){
		padding: 15px 30px;
	}
	@media only screen and (max-width: 767px){
		padding: 10px 15px;
	}
	&.sign-in{ 
		.logo-w{
			padding: 0 0 29px;
			@media (max-width: 1250px){
				padding: 0 0 15px;
			}
		}
		.forgot-pass{
			margin: -13px 0 54px 0;
			a{
				font-family: 'ProximaNova-Bold';
				color: #202020;
				&:focus,
				&:hover{
					
				}
			}
		}
		.separator{
			text-align: center;
			font-size: 12px;
			padding: 0px 0 23px;
			@media only screen and (max-width: 991px){
				padding-bottom: 1.3rem;
			}
			@media only screen and (max-width: 767px){
				padding-bottom: 1rem;
			}
		}
		.btn-microsoft{			
			svg{
				width: 18px;
				float: left;
				fill: #fff;
				left: 3px;
				position: relative;
				top: 2px;
				@media only screen and (max-width: 767px){
					width: 13px;
				}
			}
			&:focus,
			&:hover{
				border-color: #202020;
				background: #202020;
			}
		}
		.btn-link{
			color: #202020;
			margin-top: 46px;
			&:focus,
			&:hover{
				
			}
		}
	}
	.h1, h1 {
		text-align: center;
		font-size: 4.15rem;
		font-weight: 600;
		letter-spacing: 1px;
		color: #fff;
		text-align: center;
		margin: 12px 0 3px;
		@media only screen and (max-width: 1200px){
			font-size: 3rem;
			letter-spacing: 0.5px;
		}
		@media only screen and (max-width: 991px){
			font-size: 2.15rem;
			letter-spacing: 0.5px;
		}
		@media only screen and (max-width: 767px){
			font-size: 1.8rem;
			letter-spacing: 0px;
		}

	}
	.h4, h4 {
		padding: 0 0 35px 0;
    	text-align: center;
		@media only screen and (max-width: 1200px){
			font-size: 1.2rem;
		}
		@media only screen and (max-width: 991px){
			font-size: 1rem;
		}
		@media only screen and (max-width: 767px){
			letter-spacing: -0.5px;
    		font-size: 0.8rem;
		}
	}
	form {	
	padding-right: 0px;	
		p{
			padding: 0px 0 54px 0;
			font-family: 'ProximaNova-Bold';
			font-size: 24px;
			line-height: 1.4;
			margin:0;
		}
		.form-group{
			margin-bottom: 1.6rem;
			@media only screen and (max-width: 991px){
				margin-bottom: 1.3rem;
			}
			@media only screen and (max-width: 767px){
				margin-bottom: 1rem;
			}
			label{
				margin-bottom: 0.3rem;
				font-weight: 500;
				font-size: 0.8rem;
				letter-spacing: 0.2px;
			}
			.form-control {
				line-height: 1.55;
				background: #f3f3f3;
				border:1px solid #ececec;
				&:focus{
					background: #fff;
					border:1px solid #202020;
				}
			}
			textarea.form-control{
				height: 200px;
				@media only screen and (max-width: 1200px){
					height: 175px;
				}
				@media only screen and (max-width: 991px){
					height: 140px;
				}
				@media only screen and (max-width: 767px){
					height: 100px;
				}
			}

		}
	}
}

