.page-body {


    .account-nav {
        border:  solid var(--primary-orange);
        border-width: 1.5px 0;

        .account-nav-item {
            >a {
                &.active {
                    color: var(--brand);
                }

                &.active:hover {
                    color: var(--brand);
                }

                @media only screen and (max-width: 575px) {
                    font-size: 12px;
                }
            }

        }



    }
}
