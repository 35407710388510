.page-body {
    &.page-home {
        .page-item-component {
            padding: 140px 0;

            @media only screen and (max-width: 1200px) {
                padding: 110px 0;
            }

            @media only screen and (max-width: 991px) {
                padding: 65px 0;
            }
        }
    }

    .page-item-component {
        line-height: 1.2;
        padding: 50px 0;

        p {
            margin-bottom: 50px;

            @media only screen and (max-width: 1200px) {
                margin-bottom: 40px;
            }

            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }

            @media only screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        &.home-belong-content-image{
            padding:140px 0 200px 0;
        }
        
        &.image-content-right,
        &.image-content-left {
            .pic-container {
                padding: 20px 0;
            }

            .pic-container+.pic-container {
                padding-top: 50px;
            }

            .btn {
                @media only screen and (max-width: 767px) {
                    margin: 26px 0;
                }
            }
        }

        &.page-item-slider {
            .content {
                max-width: 65%;
                margin: 0 auto;

                @media only screen and (max-width: 767px) {
                    max-width: 80%;
                }

                h1,
                h2,
                h3 {
                    margin-bottom: 39px;

                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 30px;
                    }

                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                    }

                    @media only screen and (max-width: 767px) {
                        margin-bottom: 10px;
                    }
                }
            }

            .slider-content {
                padding: 45px 115px 0;

                @media only screen and (max-width: 767px) {
                    padding: 30px 45px 0;
                }
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }

            .swiper-button-next,
            .swiper-button-prev {
                transform: translateY(-50%);

                svg {
                    fill: var(--gray-4);
                    transition: all 0.3s;
                }

                &:hover {
                    svg {
                        fill: var(--white);
                    }
                }
            }
        }
    }

    .hidden-frontend {
        display: none;
    }

    &.page-home {
        .card-with-image {
            background: var(--dark);
            padding: 60px 0 290px 0;

            @media (max-width: 991px) {
                padding: 0;
                padding-bottom: 100px;
            }
        }
    }

    .card-with-image {
        background: var(--dark);
        padding: 100px 0;

        @media (max-width: 991px) {
            padding: 30px 0;
        }

        .image-wrapper {
            transform: translate(100px, 65px);

            // height: 100%;
            video {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }

            @media (max-width: 991px) {
                transform: translateX(0);
            }
        }

        .image-wrapper-reverse {
            transform: translateY(-29px) !important;
            // height: 100%;

            video {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }

            @media (max-width: 991px) {
                transform: translateX(0);
            }
        }

        .card-wrapper {
            padding: 50px 38px 50px 147px;

            @media (min-width: 991px) {
                // max-height: 460px;
                min-height: 440px;
            }

            @media (max-width: 991px) {
                padding: 30px;
            }
        }

        .card-wrapper-2 {
            padding: 50px 147px 50px 38px;
            transform: translate(100px, 65px);

            @media (min-width: 991px) {
                max-height: 460px;
                min-height: 440px;
            }

            @media (max-width: 991px) {
                padding: 30px;
                transform: none;
            }
        }
    }

    .subscribe-section {
        background: var(--dark);
        z-index: 10;
        position: relative;

        .subscribe-content {
            padding-top: 150px;
            padding-bottom: 25px;

            &.block-align-top {
                @media (max-width:991px) {
                    padding: 0;
                    transform: translateY(-45px);
                }
            }
        }

        .pic-container {
            @media (max-width: 991px) {
                min-height: 200px;
            }

        }

        .cta-wrapper {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            transform: translateY(-50%);
            background: var(--dark);


            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-height: 200px;
                display: block;
            }

            @media (max-width: 991px) {
                position: static;
                transform: translateY(-25%);
            }

            .right-col,
            .left-col {
                padding: 25px;
                background: var(--gray);
                min-height: 200px;
                height: 100%;
            }
        }

        #subscribeCol {
            min-height: 153px;
        }

        #subscribeCol,
        #formCol {
            transition:
                transform 0.4s,
                opacity 0.4s;
        }

        .hidden {
            transform: translateY(-60px);
            opacity: 0;
            display: none;
        }

        .visible {
            transform: translateY(0);
            opacity: 1;
            display: block;
        }

        .block-align-middle {
            padding-top: 50px;
            padding-bottom: 15px;

            @media (max-width:767px) {
                padding: 50px 0;
            }
        }

        #newsletter_form {
            input[type="email"] {
                position: relative;
                width: 486px;
                height: 65px;
                border-radius: 60px;
                border: none;
                outline: none;
                background: white;
                padding-left: 70px;
                background: #d7d1ca;
            }

            input[type="email"]::placeholder {
                font-size: 14px;
                font-family: "Museo Sans 700";
                font-weight: 600;
                letter-spacing: 2.1px;
                color: #f18f3f;
                text-transform: uppercase;
            }

            .arrow {
                position: absolute !important;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
            }

            .arrow.btn-link svg {
                fill: none !important;
            }

            .input-wrapper {
                width: fit-content;
                margin: auto;

                .email-input {
                    padding-right: 80px;
                }

                @media (max-width: 767px) {
                    width: 100%;

                    .email-input {
                        width: 100%;
                    }
                }

                @media (max-width: 575px) {
                    .email-input {
                        width: 100%;
                        padding-left: 18px;
                        padding-right: 45px;

                        &::placeholder {
                            font-size: 12px;
                            letter-spacing: 0px;
                        }
                    }
                }
            }

            .privacy-policy-checkbox {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: left;
                margin-top: 20px;

                @media (max-width: 767px) {
                    justify-content: start;
                }
            }

            .loading-svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
                transition: opacity 0.3s;
                opacity: 0;
                pointer-events: none;
            }

            .loading-svg.showing {
                opacity: 1;
            }

            [type="checkbox"] {

                &:not(:checked),
                &:checked {
                    position: absolute;
                    left: -9999px;
                }

                +label {
                    position: relative;
                    cursor: pointer;
                    font-size: 12px;
                    padding: 0px 0 1px 30px;
                    margin-bottom: 0;
                    color: var(--dark);

                    @media only screen and (max-width: 991px) {
                        font-size: 12px;
                    }

                    @media only screen and (max-width: 767px) {}
                }

                &:not(:checked)+label:before,
                &:checked+label:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0px;
                    height: 20px;
                    width: 20px;
                    border: 1px solid var(--primary-orange);
                    border-radius: 5px;
                    box-shadow: none;
                    background: transparent;
                    transition: all var(--transition_fast);
                    background-color: transparent;

                    @media only screen and (max-width: 767px) {}
                }

                &:checked+label:before {
                    border: 1px solid var(--primary-orange) !important;
                }

                &:not(:checked)+label:after,
                &:checked+label:after {
                    content: "";
                    position: absolute;
                    background: url("../img/check2.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    width: 12px;
                    height: 8px;
                    left: 4px;
                    top: 6px;
                    transition: all 0.2s;
                }

                /* checked mark aspect changes */
                &:not(:checked)+label:after {
                    opacity: 0;
                    transform: scale(0);
                }

                &:checked+label:after {
                    opacity: 1;
                    transform: scale(1);
                }

                /* disabled checkbox */
                &:disabled:not(:checked)+label:before,
                &:disabled:checked+label:before {
                    box-shadow: none;
                    border-color: #bbb;
                    background-color: #ddd;
                }

                &:disabled:checked+label:after {
                    color: #999;
                }

                &:disabled+label {
                    color: #aaa;
                }

                /* accessibility */
                &:checked:focus+label:before,
                &:not(:checked):focus+label:before,
                &+label:hover:before &:not(:checked):focus+label:hover:before {
                    border: 1px solid var(--brand) !important;
                }
            }

            .page-body .option.parsley-error [type="checkbox"]:checked+label:before,
            .page-body .option.parsley-error [type="checkbox"]:not(:checked)+label:before {
                animation: pulsing linear 0.5s infinite;
            }

            @keyframes pulsing {
                0% {
                    transform: scale(0.85);
                }

                70% {
                    transform: scale(1);
                    box-shadow: 0 0 0 50px rgba(#af0303, 0);
                }

                100% {
                    transform: scale(0.85);
                    box-shadow: 0 0 0 0 rgba(#af0303, 0);
                }
            }

            .back-btn-wrapper {
                width: 486px;
                margin: 0 auto;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }

    .about-alexandra {
        margin-top: 150px;
        margin-bottom: 100px;

        .after-icon {
            &::after {
                display: block;
                content: url("../img/arrow-down-orange.svg");
                margin: 20px 0;
                cursor: pointer;
            }
        }
    }

    &.page-queen-bee {
        .page-item-component {
            font-size: 20px;
            line-height: 1.6;
            padding: 0;
        }
    }

    &.page-queen-bee {
        .subscribe-section {
            margin-top: 150px;
        }
    }

    &.page-our-legacy {
        .our-elixirs {
            padding-bottom: 150px;
            padding-top: 50px;

            .right-card,
            .left-card {
                transform: translateY(0);
            }
        }
    }

    &.page-our-guardians {
        .card-with-image {
            .card-with-img-col {
                @media (max-width: 991px) {
                    padding-bottom: 30px;
                }
            }

            .card-wrapper-2 {
                max-height: unset;

                @media (max-width: 1200px) {
                    padding: 100px 110px 40px 25px;
                }

                @media (max-width: 991px) {
                    padding: 30px;
                }
            }
        }

        .card-wrapper {
            padding: 129px 38px 50px 147px;

            @media (max-width: 1200px) {
                padding: 100px 25px 40px 110px;
            }

            @media (max-width: 991px) {
                padding: 30px;
            }
        }

        .hero-with-slider-wrapper {
            padding: 0 15px;
            height: 100vh;

            @media (max-width: 991px) {
                height: auto;
            }

            @media (max-width: 767px) {
                padding: 0 32px;
            }

            .guardian-msg-wrapper {
                position: relative !important;
                padding-bottom: 350px;

                @media (max-width: 991px) {
                    max-width: unset !important;
                }

                @media (max-width: 767px) {
                    padding-bottom: 285px;
                }

                @media (max-width: 575px) {
                    padding-bottom: 200px;
                }

                .image-wrapper {
                    @media (max-width: 575px) {
                        position: static;
                        transform: none;

                        img {
                            height: 300px;
                            object-fit: cover;
                        }
                    }

                    @media (max-width: 767px) {
                        transform: translate(0px, -50%);
                    }

                    @media (max-width: 575px) {
                        transform: none;
                    }
                }
            }

            .message-card {
                @media (max-width: 575px) {
                    margin: 25px 0;
                }
            }
        }
    }

    &.page-our-elixirs {
        .hero-img-text-wrapper {
            svg {
                path {
                    stroke: white;
                }
            }
        }

        .image-content-left {
            padding: 0;
            background: var(--dark);
            margin: 100px 0;

            p {
                @media (min-width: 1200px) {
                    max-width: 75%;
                }
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                margin: -15px 0;
            }

            .row>.col-12:first-child {
                @media (max-width: 991px) {
                    margin-bottom: 20px;
                }
            }

            a {
                padding-bottom: 30px;
            }

            .pic-container {
                padding: 0;

                img {
                    width: 100%;
                    height: 100%;
                    margin: -15px 0;

                    @media (max-width: 991px) {
                        margin: 0;
                    }
                }

                @media (max-width: 991px) {
                    padding: 20px 0;
                }
            }
        }
    }

    .bg-wrapper {
        position: relative;
        min-height: 100vh;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 100%;
            background: linear-gradient(0deg, rgba(237, 230, 218, 0) 80%, var(--white-light) 99%),
                linear-gradient(180deg, rgba(0, 0, 0, 0) 67.01%, var(--dark) 95.23%);
            bottom: 0;
            display: block;
            height: 100%;
        }

        @media (max-width: 991px) {
            min-height: 65vh;
        }

        @media (max-width: 767px) {
            min-height: 50vh;
        }

        @media (max-width: 575px) {
            min-height: 40vh;
        }
    }

    .static-black-bg {
        background: var(--dark);

        .content {
            transform: translateY(-50%);
            z-index: 10;
            position: relative;

            @media (max-width: 767px) {
                transform: translateY(-10%);
            }
        }
    }

    .products {
        background: var(--white-light-2) !important;

        p {
            opacity: 0.75;
        }

        .product-description {
            padding: 20px;
        }
    }

    #terms-content {
        ol {
            font-size: 28px;
            font-weight: bold;
            font-family: "Museo Sans 700";
        }

        p {
            margin-bottom: 25px;
        }
    }

    &.page-queen-bee {
        background: var(--dark);
    }

    .all-wrapper {
        min-height: calc(100vh - 259px);
        position: relative;

        .error-content {
            position: absolute;
            top: calc(50% - 100px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.page-belong {
        background: var(--dark);
    }

    .particles-background {
        position: fixed;
        top:0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
    }

    .home-belong-content-image {
        .pic-container {
            position: relative;
        }

        img {
            box-shadow: 0 3px 47px rgba(0, 0, 0, 0.5);
        }

        .text-content {
            h1 {
                color: rgba(0, 0, 0, 0.7);
            }

            p:first-of-type {
                margin-bottom: 10px;
                letter-spacing: 2.5px;
                color: rgba(0, 0, 0, 0.7);
                font-weight: bold;
            }

            p {
                margin-bottom: 30px;
                line-height: 1.7;
            }
        }

        .flower {
            opacity: 0.1;
            width: 360px;
            position: absolute;
            top: 0;
            transform: rotateZ(360deg) translate(-40%, 60%);
            left: 0;
            z-index: -1;
            bottom: 0;
        }

        .pine {
            opacity: 0.1;
            width: 100px;
            position: absolute;
            top: 25px;
            z-index: -1;
            right: 0;
            transform: rotateY(180deg) translate(-50%) rotateZ(339deg);
        }
    }

    .member-options {
        padding: 100px 0;
        @media (max-width:991px) {
            padding: 50px 0;
        }

        .tab {
            padding: 32px 20px;
            text-align: center;
            position: relative;
            color: #4f4e56;
            box-shadow: 0 4px 40px 0 var(--dark-3);
            background: #fff;
            background: var(--white-light-2);
            overflow: hidden;

            @media (max-width:991px) {
                margin: 25px 0;
            }
            .sub-container-join {
                opacity: 0;
                display: none;
            }

            &.active {
                transform: translateY(-25px);
                height: auto;
                transition: all 0.3s ease-in-out;
                overflow: visible;

                .sub-container-join {
                    transition: opacity 0.5s ease-in-out;
                    opacity: 1;
                    display: block;
                }

            }
        }

        .sub-container {
            max-width: 70%;
            margin: 0 auto;
        }

        .line {
            display: block;
            width: 70%;
            background-color: var(--primary-orange);
            height: 1px;
            margin: 0 auto;
            margin-bottom: 15px;
        }
    }

    .from-the-heart-section {
        background: var(--white-light);
        padding-top: 70px;

        .content {
            margin-top: 10px;
        }

        .images {
            position: absolute;
            bottom: 0;
            right: 0;

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        .lady {
            top: 15%;
            left: 0;

            svg {
                path {
                    fill: var(--dark);
                    opacity: 0.1;
                }
            }

            @media (max-width: 991px) {
                top: 0;

                svg {
                    width: 150px;
                }
            }
        }

        .flower {
            opacity: 0.1;
            width: 200px;
            display: inline-block;
            vertical-align: bottom;

            @media (max-width: 991px) {
                width: 100px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        .plant {
            opacity: 0.1;
            transform: translateY(100%);
            display: inline-block;

            @media (max-width: 991px) {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .bird-wing {
            bottom: 0;
            right: 50%;
            opacity: 0.1;
            width: 200px;
            display: inline-block;

            @media (max-width: 991px) {
                width: 100px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        @media (max-width: 991px) {
            padding-bottom: 70px;
        }

        @media (max-width: 767px) {
            padding-bottom: 20px;
        }

    }

    .founder-section {
        background: var(--white-light);
        padding: 65px 0;

        @media (max-width: 767px) {
            padding: 50px 0;
        }

        @media (max-width: 575px) {
            padding: 25px 0;
        }

        .founder-img {
            position: relative;
            z-index: 10;

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }

        p {
            line-height: 1;
        }

        .centaur {
            right: 35px;
            top: 45px;
            z-index: -1;

            @media (max-width: 767px) {
                svg {
                    width: 250px;
                }
            }
        }

        .founder-row-wrapper {
            video {
                width: 100% !important;
                height: 100%;
                object-fit: cover;
                display: block;
            }

            .col-founder-pic {
                flex: 0 0 27%;
                max-width: 27%;

                @media(max-width:767px) {
                    flex: 0 0 40%;
                    max-width: 40%;
                }

                @media(max-width:575px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            .col-founder-quote {
                flex: 0 0 73%;
                max-width: 73%;

                @media(max-width:767px) {
                    flex: 0 0 60%;
                    max-width: 60%;
                }

                @media(max-width:575px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }


    }
}
